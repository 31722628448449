import * as AuthActions from '@/store/actions/auth';
import STORAGE from '@/constants/storage.constant';

import Api from '@/services/api.service';
import AuthService from '@/services/auth.service';
import DoctorService from '@/services/doctor.service';
import SecretariesService from '@/services/secretaries.service';

import jwt_decode from 'jwt-decode';

import * as Movidesk from '../../scripts/movidesk';

const state = {
  loading: true,
  error: false,
  token: null,
  doctor: {},
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  doctorId: (state) => state.doctor.id,
  userName: (state) => state.doctor.name,
  secondStage: (state) => state.doctor.second_stage,
  memedToken: (state) => state.doctor.memed_token,
  complete: (state) => state.doctor.complete,
  readOnly: (state) => state.doctor.read_only,
  permissions: (state) => jwt_decode(state.token)['permissions'],
  secretaryId: (state) => jwt_decode(state.token)['secretary_id'],
  secretaryName: (state) => jwt_decode(state.token)['secretary_name'],
  termUser: (state) => jwt_decode(state.token)['term_user'],
};

const getTokenData = (token) => {
  const {
    doctor_id: id,
    doctor_name: name,
    second_stage,
    complete,
    read_only,
    has_certificate,
    memed_token,
  } = jwt_decode(token);

  return {
    id,
    name,
    second_stage,
    complete,
    read_only,
    has_certificate,
    memed_token,
  };
};

const actions = {
  [AuthActions.AUTH_TOKEN]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_TOKEN);

      const token = localStorage.getItem(STORAGE.AUTH_TOKEN);
      Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      if (!token) {
        commit(AuthActions.AUTH_TOKEN_ERROR);
        reject('No token.');
        return;
      }

      const service = getters.secretaryId ? SecretariesService : DoctorService;

      service
        .refresh()
        .then(({ data }) => {
          const { access_token: newToken } = data;
          localStorage.clear();
          commit(AuthActions.AUTH_TOKEN_SUCCESS, newToken);
          localStorage.setItem(STORAGE.AUTH_TOKEN, newToken);
          Movidesk.initMoviDesk();
          resolve(token);
        })
        .catch(() => {
          commit(AuthActions.AUTH_TOKEN_ERROR);
          localStorage.removeItem(STORAGE.AUTH_TOKEN);
          reject('Token expired.');
        });
    });
  },
  [AuthActions.AUTH_REQUEST]: ({ commit }, loginData) => {
    return new Promise((resolve, reject) => {
      commit(AuthActions.AUTH_REQUEST);

      AuthService.login(loginData)
        .then(({ data }) => {
          const { access_token } = data;

          localStorage.setItem(STORAGE.AUTH_TOKEN, access_token);
          commit(AuthActions.AUTH_REQUEST_SUCCESS, access_token);
          resolve(access_token);
        })
        .catch((err) => {
          commit(AuthActions.AUTH_REQUEST_ERROR, err);
          localStorage.removeItem(STORAGE.AUTH_TOKEN);
          reject(err);
        });
    });
  },
  [AuthActions.AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AuthActions.AUTH_LOGOUT);
      localStorage.removeItem(STORAGE.AUTH_TOKEN);
      resolve();
    });
  },
};

const mutations = {
  [AuthActions.AUTH_TOKEN]: (state) => {
    state.loading = true;
    state.error = false;
  },
  [AuthActions.AUTH_TOKEN_SUCCESS]: (state, token) => {
    state.loading = false;
    state.token = token;
    state.doctor = getTokenData(token);
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  [AuthActions.AUTH_TOKEN_ERROR]: (state) => {
    state.loading = false;
    state.error = true;
    state.token = null;
  },
  [AuthActions.AUTH_REQUEST]: (state) => {
    state.error = false;
  },
  [AuthActions.AUTH_REQUEST_SUCCESS]: (state, token) => {
    state.token = token;
    state.doctor = getTokenData(token);
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  [AuthActions.AUTH_REQUEST_ERROR]: (state) => {
    state.error = true;
    state.token = null;
  },
  [AuthActions.AUTH_LOGOUT]: (state) => {
    state.token = null;
    Movidesk.hideMovidesk();
    Api.defaults.headers.common['Authorization'] = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
