<template>
  <div class="weekdays-selector">
    <b-field label="Tempo padrão da consulta (em minutos)"></b-field>
    <div class="weekdays-selector__days">
      <div
        @click="handleDay(time)"
        v-for="time in standartTimes"
        :key="time"
        class="day"
        :class="{ selected: isSelected(time) }"
      >
        {{ time }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    model: {
      type: Number,
      default: () => [],
    },
  },
  model: {
    prop: 'model',
    event: 'input',
  },
  data: () => ({
    standartTimes: [20, 30, 45, 60, 90, 120],
    selectedTime: Number,
  }),
  methods: {
    handleDay(time) {
      this.selectedTime = time;
    },
    isSelected(time) {
      return this.selectedTime === time;
    },
  },
  watch: {
    selectedTime() {
      this.$emit('input', this.selectedTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.weekdays-selector {
  &__days {
    display: flex;
    align-items: center;

    .day {
      padding: 6px 12px;
      border-radius: 4px;
      background: #f5f5f5;
      margin-right: 6px;
      cursor: pointer;

      &:not(.selected):hover {
        background: #e5e5e5;
      }

      &.selected {
        color: #fff;
        font-weight: 600;
        background: var(--color-primary);
      }
    }
  }
}
</style>
