import Clear from '@/layouts/Clear.vue';

export default {
  path: '/schedules',
  component: Clear,
  children: [
    {
      path: '',
      name: 'schedules.index',
      component: () => import('@/views/Schedule/ScheduleList.vue'),
    },
    {
      path: 'create',
      name: 'schedules.create',
      component: () => import('@/views/Schedule/ScheduleForm.vue'),
    },
    {
      path: ':id',
      name: 'schedules.view',
      component: () => import('@/views/Schedule/Schedule.vue'),
    },
  ],
};
