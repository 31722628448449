<template>
  <div>
    <b-button
      id="memed-button-loading"
      type="is-memed"
      icon-left="plus"
      size="is-small"
      loading
    >
      Adicionar via Memed
    </b-button>
    <b-button
      id="memed-button"
      type="is-memed"
      icon-left="plus"
      size="is-small"
      style="display: none"
    >
      Adicionar via Memed
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrescriptionsService from '../../services/prescriptions.service';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
    scheduleId: Number,
    isLoading: Boolean,
  },
  computed: {
    ...mapGetters(['memedToken', 'doctorId']),
  },
  data: () => ({
    body: document.querySelector('body'),
  }),
  methods: {
    initMemed(patient) {
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('data-color', '#ff7677');
      script.setAttribute('data-token', this.memedToken);

      script.src =
        'https://memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';

      const address = `${patient.street}, ${patient.number} ${
        patient.complement ? ', ' + patient.complement : ','
      } ${patient.neighborhood}`;

      script.onload = (data) => {
        const mdSinapsePrescricao =
          data.path[3].defaultView.MdSinapsePrescricao;

        const memedButton = data.path[3].getElementById('memed-button');
        const memedButtonLoading = data.path[3].getElementById(
          'memed-button-loading'
        );

        mdSinapsePrescricao.command.send('hub', 'core:setDictionary', {
          protocolPlural: 'Modelos de receitas',
          protocolSingular: 'Modelo de receita',
          protocolSaved: 'Modelo salvo com sucesso!',
        });

        mdSinapsePrescricao.event.add('core:moduleInit', (module) => {
          const mdHub = data.path[3].defaultView.MdHub;

          mdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
            deletePatient: false,
            removePatient: false,
            editPatient: false,
            removePrescription: false,
          });

          if (module.name === 'plataforma.prescricao') {
            memedButton.addEventListener('click', () => {
              mdHub.command
                .send('plataforma.prescricao', 'setPaciente', {
                  nome: patient.name,
                  cpf: patient.cpf,
                  endereco: address,
                  cidade: patient.city.name,
                  telefone: patient.phone,
                  idExterno: patient.cpf,
                })
                .then(() => {
                  mdHub.module.show('plataforma.prescricao');
                });
            });

            setTimeout(() => {
              memedButton.setAttribute('style', 'display:visibility');
              memedButtonLoading.setAttribute('style', 'display:none');

              this.$emit('view', true);
            }, 2000);

            mdHub.event.add('prescricaoImpressa', (prescriptionData) => {
              const { prescricao } = prescriptionData;
              const { documents, medicamentos } = prescricao;

              let prescription = '';
              let name = '';

              medicamentos.forEach((el) => {
                name += `${el.nome} `;
                prescription += `<p>${el.nome}</p>${el.posologia}<br/>`;
              });

              const data = {
                schedule_id: this.scheduleId ?? null,
                doctor_id: this.doctorId,
                patient_id: this.patient.id,
                memed_prescription_id: documents[0].prescription_id,
                memed_file_type: medicamentos[0].receituario,
                prescription,
                name,
              };

              PrescriptionsService.saveMemedPrescription({
                ...data,
              });
            });
          }
        });

        mdSinapsePrescricao.event.add('core:moduleHide', (module) => {
          if (module.moduleName === 'plataforma.prescricao') {
            this.$emit('reload', true);
          }
        });
      };
      this.body.appendChild(script);
    },
  },
  mounted() {
    this.initMemed(this.patient);
  },
  beforeDestroy() {
    delete window.MdHub;
  },
};
</script>

<style lang="scss" scoped></style>
