import Clear from '@/layouts/Clear.vue';

export default {
  path: '/discounts',
  component: Clear,
  children: [
    {
      path: '',
      name: 'discounts.index',
      component: () => import('@/views/Discounts/DiscountList.vue'),
    },
    {
      path: 'create',
      name: 'discounts.create',
      component: () => import('@/views/Discounts/DiscountForm.vue'),
    },
    {
      path: ':id/edit',
      name: 'discounts.edit',
      component: () => import('@/views/Discounts/DiscountForm.vue'),
    },
  ],
};
