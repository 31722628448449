<template>
  <div class="doctor-profile-view">
    <app-header icon="user" title="Seus dados"> </app-header>

    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <div class="form-container">
      <form @submit.prevent="onSubmit" v-if="!isLoading">
        <div class="col-12 mb-4 alert-message" v-if="!secondStage">
          Por favor, complete seu cadastro.<br />
          Só poderá utilizar o sistema após completar seu cadastro.
        </div>

        <div class="col-12 mb-4 alert-message" v-if="secondStage && !complete">
          A aprovação da sua conta está em andamento, assim que estiver tudo
          certo, poderá utilizar o sistema.
        </div>

        <div class="row">
          <div class="col-6">
            <b-field
              label="Foto pessoal"
              :type="errors.photo ? 'is-danger' : ''"
              :message="errors.photo"
              class="required"
            >
              <div class="doctor-photo">
                <img :src="doctorPhoto" alt="Doctor Photo" />
                <div @click="onSelectPhotoClick" class="select-button">
                  <b-icon icon="camera" type="is-white"></b-icon>
                </div>
              </div>
            </b-field>

            <app-cropper-image
              v-if="photoToCrop"
              :src="photoToCrop"
              :aspectRatio="1"
              class="cropper-image"
              @croppedImage="onCropperImage"
            >
            </app-cropper-image>

            <input
              ref="file"
              @change="onPhotoToCropChange($event, 'profile')"
              accept="image/*"
              style="display: none"
              type="file"
              class="required"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <app-input
              label="Nome"
              v-model="doctor.name"
              type="text"
              :errors="errors.name"
              is-required
              :disabled="true"
            ></app-input>
          </div>

          <div class="col-3">
            <app-input
              label="E-mail"
              v-model="doctor.email"
              type="email"
              autocomplete="email"
              :errors="errors.email"
              :disabled="true"
              is-required
            ></app-input>
          </div>
          <div class="col-3">
            <app-input
              label="CPF"
              v-model="doctor.cpf"
              type="tel"
              :mask="MASKS.cpf"
              :errors="errors.cpf"
              is-required
              :disabled="true"
            >
            </app-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-2">
            <b-field class="required" label="Data de nascimento">
              <b-datepicker
                trap-focus
                icon="calendar"
                :locale="'pt-BR'"
                :disabled="!isEditable"
                v-model="doctor.birthday"
                :years-range="[-100, 100]"
                placeholder="Selecionar data..."
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="col-2">
            <app-input
              label="Telefone"
              v-model="doctor.phone"
              type="tel"
              v-mask="'(##) ####-####'"
              :errors="errors.phone"
              is-required
              :disabled="true"
            ></app-input>
          </div>
          <div class="col-2">
            <app-input
              label="Celular"
              v-model="doctor.cellphone"
              type="tel"
              v-mask="'(##) #####-####'"
              :errors="errors.cellphone"
              is-required
            ></app-input>
          </div>
          <div class="col-2">
            <app-input
              label="Email transacional"
              v-model="doctor.transactional_email"
              type="string"
              :errors="errors.transactional_email"
            ></app-input>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <app-input
              label="RQE"
              v-model="doctor.rqe"
              :errors="errors.rqe"
              :disabled="true"
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-3">
            <app-input
              label="CRM"
              v-model="doctor.crm"
              type="tel"
              :errors="errors.crm"
              is-required
              :disabled="true"
            ></app-input>
          </div>

          <div class="col-3">
            <app-autocomplete
              label="Estado do CRM"
              v-model="doctor.crm_uf"
              :value="doctor.crm_uf"
              :getter="statesGetter"
              :setter="stateSetter"
              :errors="errors.crm_uf"
              field="name"
              is-required
              :disabled="true"
            ></app-autocomplete>
          </div>
        </div>

        <div
          :class="`row mt-4 mb-4 ${
            doctor.crm_photo && isEditable ? 'docs-row' : ''
          }`"
        >
          <div class="col-5">
            <app-file-picker
              label="Foto do CRM"
              v-model="doctor.crm_photo"
              :errors="errors.crm_photo"
              accept="image/*"
              required
              :disabled="!isEditable"
              @fileSelected="onCrmPhotoSelected"
              @fileDeleted="() => (this.doctor.crm_photo = '')"
            >
            </app-file-picker>

            <div v-if="doctor.crm_photo && isEditable" class="crm-preview">
              <b-loading
                :active.sync="crmPhotoIsLoading"
                :is-full-page="false"
              ></b-loading>
              <img :src="doctor.crm_photo" alt="Imagem do CRM" />
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-2">
            <tag-input
              v-model="doctor.specialties"
              :options="specialties"
              :selecteds="doctorSpecialties"
              :max="1"
              :errors="errors.specialties"
              label="Especialidade"
              placeholder="Adicione sua especialidade"
              is-required
              :disabled="true"
            ></tag-input>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12">
            <b-field
              v-if="!subspecialties.length"
              label="Especialista em"
              required
            >
              <b-tag type="is-dark" size="is-medium"
                >Nenhuma subespecialidade cadastrada</b-tag
              >
            </b-field>

            <b-field
              label="Especialista em"
              v-if="subspecialties.length && doctor.specialties.length > 0"
            >
              <b-dropdown
                expanded
                scrollable
                multiple
                aria-role="list"
                max-height="200"
                v-model="doctor.subspecialties"
              >
                <template #trigger>
                  <b-button expanded icon-left="chevron-down">
                    {{ subspecialtiesSelecteds }}
                  </b-button>
                </template>
                <b-dropdown-item custom aria-role="listitem">
                  <b-input
                    v-model="searchSubspecialty"
                    placeholder="Pesquise"
                    expanded
                  />
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  :key="subspecialty.id"
                  :value="subspecialty.id"
                  v-for="subspecialty in filteredSubspecialty"
                  :disabled="disableSubspecialty(subspecialty.id)"
                >
                  <span>{{ subspecialty.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-field
              label="Mini Currículo"
              :type="errors && errors.mini_curriculum ? 'is-danger' : ''"
              :message="
                errors && errors.mini_curriculum
                  ? errors.mini_curriculum[0]
                  : ''
              "
              :class="errors && errors.mini_curriculum ? 'required' : ''"
            >
              <editor
                v-model="doctor.mini_curriculum"
                :api-key="editorApiKey"
                :init="{
                  plugins: '',
                  toolbar: '',
                  menubar: '',
                }"
              ></editor>
            </b-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 col-lg-12 col-xl-12 subtitle-edit">
            <h2 class="subtitle">Endereço</h2>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <app-input
              label="CEP"
              @input="getCepInfo"
              v-model="doctor.cep"
              type="text"
              v-mask="'#####-###'"
              :errors="errors.cep"
              is-required
            ></app-input>
          </div>
          <div class="col-3" style="display: flex; align-items: flex-end">
            <b-button
              @click="getCepInfo(doctor.cep, true)"
              icon-left="search"
              type="is-text"
            >
              Buscar pelo CEP
            </b-button>
          </div>
          <div class="col-6">
            <app-input
              label="Rua"
              v-model="doctor.street"
              type="text"
              :errors="errors.street"
              is-required
            ></app-input>
          </div>
          <div class="col-3">
            <app-input
              label="Número"
              v-model="doctor.number"
              type="tel"
              :errors="errors.number"
              is-required
            ></app-input>
          </div>
          <div class="col-4">
            <app-input
              label="Complemento"
              v-model="doctor.complement"
              type="text"
              :errors="errors.complement"
            ></app-input>
          </div>
          <div class="col-4">
            <app-input
              label="Bairro"
              v-model="doctor.neighborhood"
              type="tel"
              :errors="errors.neighborhood"
              is-required
            ></app-input>
          </div>
          <div class="col-4">
            <app-autocomplete
              label="Cidade"
              ref="citySelector"
              v-model="doctor.city_id"
              :value="doctor.city_id"
              :getter="citiesGetter"
              :setter="citySetter"
              :errors="errors.city_id"
              field="name"
              is-required
            ></app-autocomplete>
          </div>
        </div>

        <div class="card" style="margin-top: 40px">
          <div class="card-header">
            <div class="card-header-icon">
              <b-icon icon="info-circle"></b-icon>
            </div>
            <div class="card-header-title">
              Os dados cadastrais deste quadro são exigidos pela legislação para
              criação de conta cobrança
            </div>
          </div>
          <div class="card-content custom-scroll">
            <div class="row mt-2">
              <div class="col-3">
                <app-input
                  label="Renda mensal ou receita"
                  v-model="doctor.monthly_income_or_revenue"
                  :errors="errors.monthly_income_or_revenue"
                  :mask="MASKS.money"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
              <div class="col-3">
                <app-input
                  label="Nome da Mãe"
                  v-model="doctor.mother_name"
                  type="text"
                  :errors="errors.mother_name"
                  is-required
                  :disabled="!isEditable"
                ></app-input>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-3">
                <b-field label="Selecionar Banco">
                  <b-dropdown
                    expanded
                    scrollable
                    aria-role="list"
                    max-height="200"
                    v-model="doctor.bank"
                  >
                    <template #trigger>
                      <b-button expanded icon-right="chevron-down">
                        {{ bankSelected }}
                      </b-button>
                    </template>
                    <b-dropdown-item custom aria-role="listitem">
                      <b-input
                        v-model="searchBank"
                        placeholder="Pesquise"
                        expanded
                      />
                    </b-dropdown-item>
                    <b-dropdown-item
                      aria-role="listitem"
                      :key="bank.id"
                      :value="bank.bank_number"
                      v-for="bank in filteredBanks"
                    >
                      <span>{{ bank.name_with_number }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-field>
              </div>

              <div class="col-3">
                <app-input
                  label="Agência"
                  v-model="doctor.agency"
                  type="text"
                  :errors="errors.agency"
                  is-required
                ></app-input>
              </div>

              <div class="col-3">
                <app-input
                  label="Conta"
                  v-model="doctor.account"
                  type="text"
                  :errors="errors.account"
                  is-required
                ></app-input>
              </div>

              <div v-if="showAccountComplementNumber" class="col-3">
                <app-input
                  label="Complemento da conta"
                  v-model="doctor.accountComplementNumber"
                  type="text"
                  :errors="errors.accountComplementNumber"
                  is-required
                ></app-input>
              </div>
              <div class="col-12">
                <span class="alert">
                  Obs: Utilizar uma conta bancária que esteja vinculada ao CPF
                  cadastrado para o médico!
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <app-file-picker
                  label="Foto RG ou CNH"
                  v-model="doctor.personal_document"
                  :errors="errors.personal_document"
                  accept="image/*"
                  required
                  :disabled="isEditableJuno('ID')"
                  @fileSelected="onDocumentPhotoSelected($event, 1)"
                  @fileDeleted="() => (this.doctor.personal_document = '')"
                >
                </app-file-picker>

                <span v-if="!isEditableJuno('ID')" class="alert">
                  Foto anterior rejeitada pela Juno. Por favor, envie outra para
                  prosseguir com o cadastro.
                </span>

                <div v-if="doctor.personal_document" class="document-preview">
                  <b-loading
                    :active.sync="documentPhotoIsLoading"
                    :is-full-page="false"
                  ></b-loading>
                  <img :src="doctor.personal_document" alt="Imagem RG ou CNH" />
                </div>
              </div>

              <div class="col-4">
                <app-file-picker
                  label="Selfie com o Documento"
                  v-model="doctor.selfie_document"
                  :errors="errors.selfie_document"
                  accept="image/*"
                  required
                  :disabled="isEditableJuno('SELFIE')"
                  @fileSelected="onDocumentPhotoSelected($event, 2)"
                  @fileDeleted="() => (this.doctor.selfie_document = '')"
                >
                </app-file-picker>

                <span v-if="!isEditableJuno('SELFIE')" class="alert">
                  Foto anterior rejeitada pela Juno. Por favor, envie outra para
                  prosseguir com o cadastro.
                </span>

                <div
                  v-if="doctor.selfie_document && !isEditableJuno('SELFIE')"
                  class="document-preview"
                >
                  <b-loading
                    :active.sync="selfieDocumentIsLoading"
                    :is-full-page="false"
                  ></b-loading>
                  <img
                    :src="doctor.selfie_document"
                    alt="Selfie com RG ou CNH"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 button-container">
            <b-button
              @click="onSubmit"
              :loading="isLoading"
              :disabled="isLoading"
              type="is-primary"
            >
              Salvar
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import BanksService from '@/services/banks.service';

import CepService from '@/services/cep.service';
import DoctorService from '@/services/doctor.service';

import SpecialtiesService from '@/services/specialties.service';
import Autocomplete from '@/components/inputs/Autocomplete.vue';
import CropperImage from '@/components/CropperImage.vue';

import TagInput from '@/components/inputs/TagInput.vue';
import MASKS from '@/constants/masks.constant';

import { AUTH_TOKEN } from '@/store/actions/auth';
import { mapGetters } from 'vuex';

import * as moment from 'moment';
import FileMixin from '@/mixins/FileMixin';

import DOCUMENTS_STATUS from '@/constants/document_juno_status.constant';

import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    'app-autocomplete': Autocomplete,
    'tag-input': TagInput,
    'app-cropper-image': CropperImage,
    editor: Editor,
  },
  mixins: [FileMixin],
  props: {
    isModal: Boolean,
  },

  data: () => ({
    MASKS: MASKS,
    editorApiKey: process.env.VUE_APP_TINY_MCE_KEY,

    isLoading: false,
    searchSubspecialty: '',
    crmPhotoIsLoading: false,
    documentPhotoIsLoading: false,
    selfieDocumentIsLoading: false,
    showAccountComplementNumber: false,
    photoIsLoading: false,
    photoToCrop: '',
    specialties: [],
    subspecialties: [],
    doctorSpecialties: [],
    doctorSubspecialties: [],
    doctorId: Number,
    errors: {},
    banks: [],
    searchBank: '',
    doctor: {
      name: null,
      email: null,
      cpf: null,
      birthday: [],
      photo: null,
      transactional_email: null,
      phone: null,
      cellphone: null,
      mini_curriculum: null,
      crm: null,
      crm_uf: null,
      crm_photo: null,
      rqe: null,
      cep: null,
      street: null,
      number: null,
      neighborhood: null,
      complement: null,
      city_id: null,
      personal_document: null,
      selfie_document: null,
      specialties: [],
      subspecialties: [],
      mother_name: null,
      monthly_income_or_revenue: 0,
      juno_documents_status: {
        type: null,
        status: null,
      },
      bank: null,
      agency: null,
      account: null,
      accountComplementNumber: null,
    },
  }),
  computed: {
    ...mapGetters(['secondStage', 'complete']),
    subspecialtiesSelecteds: function () {
      let result = [];
      let me = this;
      this.subspecialties.forEach(function (subspecialty) {
        if (me.doctor.subspecialties.indexOf(subspecialty.id) > -1) {
          result.push(subspecialty.name);
        }
      });
      if (result.length <= 0) {
        return 'Selecione até 3 subspecialidades';
      }
      return result.join(', ');
    },
    bankSelected() {
      let bank = this.banks.filter((bank) => {
        if (this.doctor.bank == bank.bank_number) return bank;
      })[0];

      return bank ? bank.name_with_number : 'Escolha um banco';
    },
    doctorPhoto() {
      return this.doctor.photo ?? require('@/assets/img/user.png');
    },
    isEditable() {
      return this.secondStage == 1 ? false : true;
    },
    filteredSubspecialty() {
      return this.subspecialties.filter(
        (subspecialty) =>
          subspecialty.name
            .toLowerCase()
            .indexOf(this.searchSubspecialty.toLowerCase()) >= 0
      );
    },
    filteredBanks() {
      return this.banks.filter(
        (bank) =>
          bank.name.toLowerCase().indexOf(this.searchBank.toLowerCase()) >= 0
      );
    },
  },
  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    banksGetter: BanksService.search,
    banksSetter: BanksService.getBank,
    citySetter: CitiesService.getId,
    stateSetter: StatesService.getId,
    onSubmit() {
      const {
        crm,
        cpf,
        birthday,
        cep,
        agency,
        account,
        monthly_income_or_revenue,
        mini_curriculum,
      } = this.doctor;

      const data = {
        ...this.doctor,
        second_stage: true,
        crm: crm ? crm.replace(/\D/g, '') : null,
        cpf: cpf ? cpf.replace(/\D/g, '') : null,
        cep: cep ? cep.replace(/\D/g, '') : null,
        mini_curriculum: mini_curriculum
          ? mini_curriculum.replace(/(\r\n|\n|\r)/gm, '')
          : null,
        monthly_income_or_revenue: this.unmask(monthly_income_or_revenue),
        agency: agency ? agency.replace(/\D/g, '') : null,
        account: account ? account.replace(/\D/g, '') : null,
        birthday: moment(birthday).format('YYYY-MM-DD'),
      };

      this.isLoading = true;
      this.errors = {};

      DoctorService.put(data, this.doctorId)
        .then(({ data }) => {
          this.doctorSpecialties = data.specialties;
          this.doctorSubspecialties = data.subspecialties;

          if (!this.$store.getters.complete) {
            this.$buefy.snackbar.open({
              message:
                'Cadastro completado com sucesso, estando tudo certo, dentro de 24 horas úteis o sistema estará liberado para o uso!',
              duration: 5000,
            });
            this.$store.dispatch(AUTH_TOKEN);
          } else {
            this.$buefy.snackbar.open('Perfil atualizado com sucesso.');
          }
        })
        .catch(({ response }) => {
          if (response.status === 403) {
            this.$buefy.snackbar.open(response.data.message);
          } else {
            const message =
              response.status === 422 || response.status === 400
                ? 'Verificar campos não cadastrados ou inválidos!'
                : 'Erro ao atualizar dados!';

            this.$buefy.snackbar.open(message);
            this.errors = response.data.message;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    unmask(value) {
      if (!value) return value;

      const realValue = value.split('R$').pop();
      return realValue.trim().replaceAll('.', '').replace(',', '.');
    },
    getCepInfo(cep, onClick = false) {
      if (!cep) return;

      if (!!this.doctorId && !onClick) return;

      cep = cep.replace(/\D/g, '');
      if (cep.length < 8) return;

      CepService.getCepInfo(cep).then(({ data }) => {
        this.doctor.street = data.logradouro;
        this.doctor.neighborhood = data.bairro;

        this.$refs.citySelector.loadResourceById(data.city_id);
      });
    },
    loadDoctorInfo() {
      DoctorService.getId(this.doctorId)

        .then(({ data: doctor }) => {
          this.doctor = {
            ...doctor,
            email: doctor.user.email,
            city: { name: !doctor.city ? '' : doctor.city.name },
            birthday: doctor.birthday ? new Date(doctor.birthday) : null,
            specialties: doctor.specialties.map((specialty) => specialty.id),
            subspecialties: doctor.subspecialties.map(
              (subspecialty) => subspecialty.id
            ),
            pfx_document: doctor.doctor_certificate
              ? doctor.doctor_certificate.certificate_path.split('/').pop()
              : '',
            monthly_income_or_revenue: Number(
              doctor.monthly_income_or_revenue
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          };

          if (!this.isEditableJuno('ID')) this.doctor.personal_document = '';
          if (!this.isEditableJuno('SELFIE')) this.doctor.selfie_document = '';

          this.doctorSpecialties = doctor.specialties;
          this.doctorSubspecialties = doctor.subspecialties;
        })
        .finally(() => (this.isLoading = false));
    },
    loadSpecialties() {
      SpecialtiesService.getSpecialties().then(({ data }) => {
        this.specialties = data;
      });
    },
    onFileDelete() {
      this.doctor.photo = '';
    },
    onCrmPhotoSelected(value) {
      this.crmPhotoIsLoading = true;
      this.doctor.crm_photo = URL.createObjectURL(value);

      DoctorService.setPhoto(this.doctorId, value, 2)
        .then(({ data }) => (this.doctor.crm_photo = data.url))
        .finally(() => {
          this.crmPhotoIsLoading = false;
        });
    },
    onDocumentPhotoSelected(value, type) {
      if (type === 1) {
        this.documentPhotoIsLoading = true;
        this.doctor.personal_document = URL.createObjectURL(value);
      } else {
        this.selfieDocumentIsLoading = true;
        this.doctor.selfie_document = URL.createObjectURL(value);
      }

      DoctorService.setPhoto(this.doctorId, value, type, 1).finally(() => {
        this.documentPhotoIsLoading = false;
        this.selfieDocumentIsLoading = false;
      });
    },
    onCropperImage(value) {
      this.photoIsLoading = true;
      this.doctor.photo = URL.createObjectURL(value);

      DoctorService.setPhoto(this.doctorId, value, 1)
        .then(({ data }) => (this.doctor.photo = data.url))
        .finally(() => {
          this.photoToCrop = '';
          this.photoIsLoading = false;
        });
    },
    async onPhotoToCropChange(event, flag) {
      const [file] = event.target.files;

      if (flag == 'profile') {
        this.photoToCrop = await this.fileToBase64(file);
      }
    },
    onSelectPhotoClick() {
      if (this.photoToCrop) this.photoToCrop = null;
      this.$refs.file.click();
    },
    isEditableJuno(TYPE) {
      if (!this.doctor.juno_documents_status) return false;

      const doc = this.doctor.juno_documents_status.filter(
        (document) => document.type == TYPE
      )[0];

      return DOCUMENTS_STATUS[doc.status] !== DOCUMENTS_STATUS.REJECTED;
    },
    disableSubspecialty(value) {
      return (
        this.doctor.subspecialties.length === 3 &&
        this.doctor.subspecialties.indexOf(value) === -1
      );
    },
    loadBanks() {
      BanksService.search().then(({ data }) => {
        this.banks = data;
      });
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.doctorId = this.$store.getters.doctorId;

    this.loadDoctorInfo();
    this.loadSpecialties();
    this.loadBanks();
  },

  watch: {
    'doctor.specialties'() {
      if (this.doctor.specialties[0])
        SpecialtiesService.getSubspecialties(this.doctor.specialties[0]).then(
          ({ data }) => (this.subspecialties = data)
        );
    },
    'doctor.bank'() {
      if (this.doctor.bank === 104) this.showAccountComplementNumber = true;
      else this.showAccountComplementNumber = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-message {
  color: var(--color-danger);
}

.alert {
  color: var(--color-primary);
  font-style: italic;
}

.subtitle-edit {
  display: flex;

  h2 {
    margin-right: 10px;
  }
}

.form-container {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px -4px lightgrey;
  padding: 24px;

  .doctor-photo {
    width: 130px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .select-button {
      width: 35px;
      height: 35px;

      border: 2px solid #fff;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      background: var(--color-primary);

      position: relative;
      top: -45px;
      left: 95px;

      cursor: pointer;
    }
  }

  .docs-row {
    height: 260px;
  }

  .crm-preview {
    position: relative;
    width: 250px;
    height: 150px;
    margin-top: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  .document-preview {
    margin-top: 15px;
  }
}

form {
  .row:not(:first-child) > div[class^='col-'] {
    margin-top: 12px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1280px) {
  .form-container {
    .picture-preview {
      width: 160px;
    }
    .crm-preview {
      width: 200px;
    }
  }
}
</style>
