import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { DialogProgrammatic as Dialog } from 'buefy';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { AUTH_TOKEN } from '@/store/actions/auth';
import DoctorService from '@/services/doctor.service';
import DashboardLayout from '@/layouts/Dashboard.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import ContactUs from '@/views/ContactUs.vue';
import Financial from '@/views/Financial.vue';
import Schedule from '@/views/Schedule.vue';
import Timetable from '@/views/Timetable.vue';
import MedicalRecordModel from '@/views/MedicalRecordModel.vue';
import DoctorProfile from '@/views/DoctorProfile.vue';
import SecretaryProfile from '@/views/SecretaryProfile.vue';
import Appointment from '@/views/Appointment.vue';
import Dashboard from '@/views/Dashboard.vue';
import SCHEDULES_ROUTES from '@/router/schedules.routes';
import PRESCRIPTION_MODELS_ROUTES from '@/router/prescription-models.routes';
import PATIENTS_ROUTES from '@/router/patients.routes';
import SECRETARIES_ROUTES from '@/router/secretaries.routes';
import PROCEDURES_ROUTES from '@/router/procedures.routes';
import DISCOUNTS_ROUTES from '@/router/discounts.routes';
import DIGITAL_CERTIFICATE from '@/router/digital-certificate.routes';

import TermsService from '@/services/terms.service';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (!store.getters.secretaryId && !store.getters.termUser.updated) {
    if (typeof store.getters.termUser.term.id != 'undefined') {
      TermsService.get().then(({ data }) => {
        Dialog.confirm({
          title: data.name,
          message: data.term,
          cancelText: 'Cancelar',
          confirmText: 'Aceito',
          type: 'is-success',
          onConfirm: () => {
            DoctorService.patch(
              { term: store.getters.termUser.term.id },
              store.getters.doctorId
            ).then(() => {
              store.dispatch(AUTH_TOKEN);
              store.getters.termUser.updated = 1;
            });
          },
          onCancel: () => {
            store.dispatch(AUTH_LOGOUT).then(() => router.push('/login'));
          },
        });
      });
    }
  }

  if (store.getters.readOnly && to.name !== 'doctor-profile') {
    next('/doctor-profile');
    return;
  }

  if (store.getters.isAuthenticated) {
    next();
    return;
  }

  next('/login');
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '',
        name: 'index',
        component: Home,
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'schedule',
        name: 'schedule',
        component: Schedule,
      },
      {
        path: 'timetable',
        name: 'timetable',
        component: Timetable,
      },
      {
        path: 'medical-records',
        name: 'medical-records',
        component: MedicalRecordModel,
      },
      {
        path: 'doctor-profile',
        name: 'doctor-profile',
        component: DoctorProfile,
      },
      {
        path: 'secretary-profile',
        name: 'secretary-profile',
        component: SecretaryProfile,
      },
      {
        path: 'appointments',
        name: 'appointments',
        component: Appointment,
      },
      {
        path: 'contact-us',
        name: 'contact-us',
        component: ContactUs,
      },
      {
        path: 'financial',
        name: 'financial',
        component: Financial,
      },
      SCHEDULES_ROUTES,
      PRESCRIPTION_MODELS_ROUTES,
      PATIENTS_ROUTES,
      SECRETARIES_ROUTES,
      PROCEDURES_ROUTES,
      DISCOUNTS_ROUTES,
      DIGITAL_CERTIFICATE,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
