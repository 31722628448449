<template>
  <div class="register-page">
    <div class="register-page__form-container custom-scroll">
      <app-header
        goes-back
        icon="sign-in-alt"
        class="app-header"
        title="Cadastrar-se"
      >
      </app-header>
      <form @submit.prevent="register">
        <div class="row">
          <div class="col-6">
            <b-field
              label="Foto pessoal"
              :type="errors.photo ? 'is-danger' : ''"
              :message="errors.photo"
              class="required"
            >
              <div class="doctor-photo">
                <img :src="doctorPhoto" alt="Doctor Photo" />
                <div @click="onSelectPhotoClick" class="select-button">
                  <b-icon icon="camera" type="is-white"></b-icon>
                </div>
              </div>
            </b-field>

            <app-cropper-image
              v-if="photoToCrop"
              :src="photoToCrop"
              :aspectRatio="1"
              class="cropper-image"
              @croppedImage="onCropperImage"
            >
            </app-cropper-image>

            <input
              ref="file"
              @change="onPhotoToCropChange($event)"
              accept="image/*"
              style="display: none"
              type="file"
              class="required"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              type="text"
              label="Nome"
              is-required
              v-model="doctor.name"
              :errors="errors.name"
            ></app-input>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              label="CPF"
              is-required
              v-model="doctor.cpf"
              :errors="errors.cpf"
              v-mask="'###.###.###-##'"
            ></app-input>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 mt-2">
            <app-input
              type="email"
              is-required
              label="E-mail"
              autocomplete="email"
              v-model="doctor.email"
              :errors="errors.email"
            ></app-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              is-required
              label="Telefone"
              v-model="doctor.phone"
              :errors="errors.phone"
              v-mask="'(##) ####-####'"
            ></app-input>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              is-required
              label="Telefone Celular"
              v-mask="'(##) #####-####'"
              :errors="errors.cellphone"
              v-model="doctor.cellphone"
            ></app-input>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              is-required
              label="Nº CRM"
              v-model="doctor.crm"
              :errors="errors.crm"
            ></app-input>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mt-2">
            <app-autocomplete
              flag="crm"
              is-required
              field="name"
              label="Estado do CRM"
              v-model="doctor.crm_uf"
              :getter="statesGetter"
              :errors="errors.crm_uf"
            ></app-autocomplete>
          </div>
          <div class="col-lg-5 col-md-12 col-sm-12 mt-2">
            <app-file-picker
              accept="image/*"
              label="Foto do CRM"
              v-model="doctor.crm_photo"
              :errors="errors.crm_photo"
              @fileSelected="onCrmPhotoSelected"
              @fileDeleted="() => (this.doctor.crm_photo = '')"
            >
            </app-file-picker>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 col-sm-12 mt-2">
            <app-input
              label="RQE"
              v-model="doctor.rqe"
              :errors="errors.rqe"
            ></app-input>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mt-2">
            <b-field
              label="Especialidade"
              :type="errors.specialties ? 'is-danger' : ''"
              :message="errors.specialties ? errors.specialties[0] : ''"
              class="required"
            >
              <b-dropdown
                v-model="doctor.specialties"
                aria-role="list"
                scrollable
                max-height="200"
                expanded
              >
                <template #trigger>
                  <b-button
                    icon-right="chevron-down"
                    expanded
                    v-bind:class="{ dropdownOnError: errors.specialties }"
                  >
                    {{ selectedSpecialty }}
                  </b-button>
                </template>
                <b-dropdown-item custom aria-role="listitem">
                  <b-input
                    v-model="searchSpecialty"
                    placeholder="Pesquise"
                    expanded
                  />
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="specialty in filteredSpecialty"
                  :key="specialty.id"
                  :value="specialty.id"
                  aria-role="listitem"
                >
                  <span>{{ specialty.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
              :loading="isLoading"
              :disabled="isLoading"
            >
              Cadastrar
            </b-button>
          </div>
          <div class="col-12 mt-2">
            <router-link :to="{ name: 'login' }">
              <b-button type="is-text" expanded> Já tem cadastro? </b-button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service';
import StatesService from '@/services/states.service';
import SpecialtiesService from '@/services/specialties.service';
import Autocomplete from '@/components/inputs/Autocomplete.vue';
import MASKS from '@/constants/masks.constant';
import FileMixin from '@/mixins/FileMixin';
import TermsService from '@/services/terms.service';
import CropperImage from '@/components/CropperImage.vue';

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default {
  mixins: [FileMixin],
  components: {
    'app-autocomplete': Autocomplete,
    'app-cropper-image': CropperImage,
  },
  data: () => ({
    MASKS: MASKS,
    isLoading: false,
    searchSpecialty: '',
    specialties: [],
    errors: {},

    doctor: {
      crm: '',
      crm_uf: '',
      crm_photo: '',
      rqe: '',
      cpf: '',
      email: '',
      name: '',
      specialties: '',
      photo: null,
    },
    term: null,
    photoToCrop: '',
    promises: [],
  }),
  methods: {
    statesGetter: StatesService.search,
    openTerm() {
      this.register();
    },
    register() {
      const { crm, cpf } = this.doctor;

      const data = {
        ...this.doctor,
        crm: crm.replace(/\D/g, ''),
        cpf: cpf.replace(/\D/g, ''),
      };

      if (data.specialties) data.specialties = [data.specialties];

      this.errors = {};
      this.isLoading = true;

      AuthService.register(data)
        .then(() => {
          this.$buefy.snackbar.open({
            message:
              'Cadastro realizado com sucesso, favor aguardar o nosso contato, obrigado.',
            duration: 10000,
          });
          this.$router.push({ name: 'login' });
        })
        .catch(({ response }) => {
          setTimeout(() => (this.isLoading = false), 300);

          const { status, data } = response;

          if (status === 422) this.errors = data.errors;
          else
            this.$buefy.snackbar.open(
              data.message ||
                'Erro ao tentar realizar o cadastro. Tente novamente mais tarde.'
            );
        });
    },
    loadSpecialties() {
      SpecialtiesService.getSpecialties().then(({ data }) => {
        this.specialties = data;
      });
    },
    loadTerm() {
      TermsService.get().then(({ data }) => {
        this.term = data;
      });
    },
    onCrmPhotoSelected(value) {
      this.fileToBase64(value).then(
        (base64) => (this.doctor.crm_photo = base64)
      );
    },
    onSelectPhotoClick() {
      if (this.photoToCrop) this.photoToCrop = null;
      this.$refs.file.click();
    },
    onCropperImage(value) {
      this.fileToBase64(value).then((base64) => (this.doctor.photo = base64));
    },
    async onPhotoToCropChange(event) {
      const [file] = event.target.files;
      this.photoToCrop = await this.fileToBase64(file);
    },
  },
  mounted() {
    this.loadTerm();
    this.loadSpecialties();
  },
  computed: {
    selectedSpecialty: function () {
      let selectedSpecialty = this.specialties.find(
        (specialty) => specialty.id === this.doctor.specialties
      );

      if (selectedSpecialty == null) {
        return 'Adicione sua especialidade';
      }

      return selectedSpecialty.name;
    },
    filteredSpecialty() {
      return this.specialties.filter(
        (specialty) =>
          specialty.name
            .toLowerCase()
            .indexOf(this.searchSpecialty.toLowerCase()) >= 0
      );
    },
    doctorPhoto() {
      return this.doctor.photo ?? require('@/assets/img/user.png');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdownOnError {
  border-color: #dc3545;
}

.register-page {
  height: 100%;
}

.doctor-photo {
  width: 130px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .select-button {
    width: 35px;
    height: 35px;

    border: 2px solid #fff;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-primary);

    position: relative;
    top: -45px;
    left: 95px;

    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .register-page {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(var(--vh, 1vh) * 100);

    &__form-container {
      width: 90vw;
      height: 85vh;
      overflow: auto;

      padding: 5%;
    }
  }
}

@media screen and (min-width: 760px) {
  .register-page {
    &__form-container {
      height: 95vh;
    }
  }
}

@media screen and (min-width: 1024px) {
  .register-page {
    padding: 10%;
    overflow: hidden;

    &__form-container {
      .app-header {
        margin-top: -80px;
        margin-bottom: 50px;
      }

      height: 70vh;
      width: 80vw;
    }
  }
}

@media screen and (max-width: 1400px) {
  .register-page {
    &__form-container {
      height: 100vh;
    }
  }
}
</style>
