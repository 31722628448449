<template>
  <div class="dynamic-field">
    <div class="dynamic-field__name">
      <b-tooltip :label="typeLabel">
        <b-icon v-if="icon" :icon="icon"></b-icon>
      </b-tooltip>
      <div v-if="!isEditing" @click="edit()" class="label-container">
        <p>{{ model.label }}</p>
        <b-tooltip label="Editar label">
          <b-icon icon="pencil-alt"></b-icon>
        </b-tooltip>
      </div>
      <input
        v-if="isEditing"
        ref="input"
        @keyup.enter="edit"
        @keyup.esc="edit"
        v-model="model.label"
        type="text"
      />
    </div>
    <div class="dynamic-field__style">
      <div v-if="!model.field_model_type" class="untyped">
        <p class="untyped__label">Selecione o tipo do campo:</p>
        <b-button
          @click="setType(1)"
          type="is-text"
          size="is-small"
          icon-left="calendar"
        >
          Campo de Data
        </b-button>
        <b-button
          @click="setType(2)"
          type="is-text"
          size="is-small"
          icon-left="align-left"
        >
          Campo de Texto
        </b-button>
        <b-button
          @click="setType(3)"
          type="is-text"
          size="is-small"
          icon-left="code"
        >
          Campo de Texto Customizável
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
  },
  data: () => ({
    model: {},
    isEditing: false,
  }),
  computed: {
    icon() {
      const types = {
        [1]: 'calendar',
        [2]: 'align-left',
        [3]: 'code',
      };

      return types[this.model.field_model_type] ?? null;
    },
    typeLabel() {
      const labels = {
        [1]: 'Data',
        [2]: 'Texto',
        [3]: 'Texto Customizável',
      };

      return labels[this.model.field_model_type] ?? null;
    },
  },
  methods: {
    edit() {
      if (this.isEditing && !this.model.label) return;

      this.isEditing = !this.isEditing;
      this.isEditing && this.$nextTick(() => this.$refs.input.focus());
    },
    setType(type) {
      this.isEditing = false;
      this.model.field_model_type = type;
    },
  },
  watch: {
    model(newModel) {
      this.$emit('update', newModel);
    },
  },
  mounted() {
    this.model = { ...this.field };
  },
};
</script>

<style lang="scss" scoped>
.dynamic-field {
  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .label-container {
      display: flex;
      align-items: center;
      margin-left: 8px;
      cursor: pointer;

      p {
        margin-right: 6px;
      }

      .icon {
        font-size: 0.9rem;
      }
    }

    input {
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 8px 12px;
      font-size: 0.9rem;
      background: none;
      outline: none;
    }
  }

  &__style {
    .untyped {
      &__label {
        display: inline-block;
        font-size: 0.9rem;
        margin-bottom: 8px;
        margin-right: 8px;
      }
    }
  }
}
</style>
