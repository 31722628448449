window.mdChatClient = 'F1FC65BDD5A24F12B4A229CAA9B54AB3';

export function initMoviDesk() {
  const script = document.createElement('script');
  script.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';

  document.head.appendChild(script);

  setTimeout(() => {
    window.movideskChatWidget.show();
  }, 2000);
}

export function hideMovidesk() {
  window.movideskChatWidget.hide();
}
