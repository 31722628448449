<template>
  <div class="home-view">
    <app-header icon="home" title="Início"></app-header>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
