<template>
  <div>
    <div class="dashboard-page">
      <app-header icon="home" title="Dashboard"></app-header>
      <section>
        <div class="row mt-2">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="card-header-icon">
                  <b-icon icon="clock"></b-icon>
                </div>
                <div class="card-header-title">Selecionar Período</div>
              </div>
              <div class="card-content">
                <div class="period-selector p-2">
                  <b-field>
                    <b-select
                      @input="onPeriodFilterChange"
                      expanded
                      name="period"
                      icon="filter"
                      v-model="periodFilter"
                      placeholder="Selecionar"
                    >
                      <option
                        v-for="period of periods"
                        :key="period.id"
                        :value="period.id"
                      >
                        {{ period.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div v-if="isCustomPeriod" class="p-2">
                  <b-field
                    label="Selecionar datas"
                    class="custom-period-selector"
                  >
                    <b-datepicker
                      @input="onCustomPeriodChange"
                      placeholder="Clique para selecionar..."
                      icon="calendar"
                      range
                    >
                    </b-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="card-header-icon">
                  <b-icon icon="clipboard-list"></b-icon>
                </div>
                <div class="card-header-title">Consultas</div>
              </div>
              <div class="card-content">
                <b-loading
                  :is-full-page="false"
                  :active.sync="isloadingScheduleInfos"
                  :can-cancel="false"
                />
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="control">
                      <div class="box">
                        <h6>TOTAL DE CONSULTAS</h6>
                        <h2>
                          {{ schedulesInfo.total }}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="control">
                      <div class="box">
                        <h6>TOTAL DE CONSULTAS REALIZADAS</h6>
                        <h2>{{ schedulesInfo.performeds }}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="control">
                      <div class="box">
                        <h6>VALOR TOTAL DAS CONSULTAS</h6>
                        <h2>R${{ schedulesInfo.amount }}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6 col-6">
                    <div class="card">
                      <div class="card-content">
                        <CountCreatedByMonth
                          v-if="!isloadingScheduleInfos"
                          :items="schedulesInfo.created_by_date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-6">
                    <div class="card">
                      <div class="card-content">
                        <AmountByMonth
                          v-if="!isloadingScheduleInfos"
                          :items="schedulesInfo.growing_by_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="card-header-icon">
                  <b-icon icon="hospital-user"></b-icon>
                </div>
                <div class="card-header-title">Pacientes</div>
              </div>
              <div class="card-content">
                <b-loading
                  :is-full-page="false"
                  :active.sync="isloadingPatientInfos"
                  :can-cancel="false"
                />

                <div class="col-12 mt-2">
                  <div class="card p-2">
                    <div class="card-header">
                      <div class="card-header-icon">
                        <b-icon icon="clipboard-list"></b-icon>
                      </div>
                      <div class="card-header-title">
                        Por quantidade de consultas
                      </div>
                    </div>
                    <b-table
                      @page-change="loadScheduleCountPatientsTable"
                      :data="patientsInfo.patients_by_count.items"
                      :total="patientsInfo.patients_by_count.total"
                      aria-previous-label="Página Anterior"
                      aria-current-label="Página Atual"
                      aria-next-label="Próxima Página"
                      aria-page-label="Página"
                      backend-pagination
                      paginated
                    >
                      <b-table-column v-slot="props" field="name" label="Nome">
                        {{ props.row.name }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="name"
                        label="Qtd. Consultas"
                      >
                        {{ props.row.schedules_count }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        label="Opções"
                        cell-class="width-100"
                      >
                        <router-link
                          :to="{
                            name: 'patients.edit',
                            params: { id: props.row.id },
                          }"
                        >
                          <b-tooltip
                            :label="
                              props.row.is_editable ? 'Editar' : 'Visualizar'
                            "
                          >
                            <b-button
                              type="is-secondary"
                              size="is-small"
                              :icon-right="
                                props.row.is_editable ? 'pencil-alt' : 'eye'
                              "
                            />
                          </b-tooltip>
                        </router-link>
                      </b-table-column>
                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="frown" size="is-large"></b-icon>
                            </p>
                            <p>Nenhum registro encontrado.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <div class="card p-2">
                    <div class="card-header">
                      <div class="card-header-icon">
                        <b-icon icon="dollar-sign"></b-icon>
                      </div>
                      <div class="card-header-title">
                        Por valor de consultas
                      </div>
                    </div>
                    <b-table
                      @page-change="loadScheduleAmountPatientsTable"
                      :data="patientsInfo.patients_by_amount.items"
                      :total="patientsInfo.patients_by_amount.total"
                      aria-previous-label="Página Anterior"
                      aria-current-label="Página Atual"
                      aria-next-label="Próxima Página"
                      aria-page-label="Página"
                      backend-pagination
                      paginated
                    >
                      <b-table-column v-slot="props" field="name" label="Nome">
                        {{ props.row.name }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="name"
                        label="Qtd. Consultas"
                      >
                        {{ props.row.schedules_sum | currency }}
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        label="Opções"
                        cell-class="width-100"
                      >
                        <router-link
                          :to="{
                            name: 'patients.edit',
                            params: { id: props.row.id },
                          }"
                        >
                          <b-tooltip
                            :label="
                              props.row.is_editable ? 'Editar' : 'Visualizar'
                            "
                          >
                            <b-button
                              type="is-secondary"
                              size="is-small"
                              :icon-right="
                                props.row.is_editable ? 'pencil-alt' : 'eye'
                              "
                            />
                          </b-tooltip>
                        </router-link>
                      </b-table-column>
                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="frown" size="is-large"></b-icon>
                            </p>
                            <p>Nenhum registro encontrado.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                </div>

                <div class="row mt-4 p-2">
                  <div class="col-sm-12 col-md-6 col-6">
                    <div class="card">
                      <div class="card-content">
                        <CountCreatedByMonth
                          v-if="!isloadingPatientInfos"
                          :items="patientsInfo.created_by_date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-6">
                    <div class="card">
                      <div class="card-content">
                        <AmountByMonth
                          v-if="!isloadingPatientInfos"
                          :items="patientsInfo.growing_by_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DashboardService from '@/services/dashboard.service';

import PERIODS from '@/constants/dashboard/periods-filter.constant';
import AmountByMonth from '@/components/charts/doctors/AmountByMonth';
import CountCreatedByMonth from '@/components/charts/doctors/CountCreatedByMonth';

import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Dashboard',
  components: {
    CountCreatedByMonth,
    AmountByMonth,
  },
  data: () => ({
    periodFilter: 1,
    periods: PERIODS,
    selectedPeriod: null,
    isloadingScheduleInfos: false,
    patientsChartsInfo: false,
    patientsCountTable: false,
    patientsAmountTable: false,
    schedulesInfo: {
      amount: 0,
      performeds: 0,
      total: 0,
      created_by_date: [],
      growing_by_date: [],
    },
    patientsInfo: {
      patients_by_amount: {
        current_page: 1,
      },
      patients_by_count: {
        current_page: 1,
      },
      created_by_date: [],
      growing_by_date: [],
    },
  }),
  computed: {
    ...mapGetters(['doctorId']),
    isCustomPeriod() {
      const period = this.periods.filter(
        (period) => period.id == this.periodFilter
      )[0];

      return period.isCustom ?? false;
    },
    isloadingPatientInfos() {
      return !(
        this.patientsCountTable &&
        this.patientsAmountTable &&
        this.patientsChartsInfo
      );
    },
  },

  filters: {
    money(value) {
      return (
        'R$ ' + parseFloat(value).toFixed(2).replace(',', '.').replace('.', ',')
      );
    },
  },
  methods: {
    loadSchedulesInfo() {
      const params = {
        doctor_id: this.doctorId,
        period: this.selectedPeriod,
      };

      DashboardService.getSchedulesInfo({ ...params })
        .then(({ data }) => {
          this.schedulesInfo = { ...data };
        })
        .finally(() => (this.isloadingScheduleInfos = false));
    },
    loadScheduleAmountPatientsTable() {
      const params = {
        doctor_id: this.doctorId,
        period: this.selectedPeriod,
        only_patients: true,
        type: 'schedules_sum',
        page: this.patientsInfo.patients_by_amount.current_page,
      };

      DashboardService.getPatientsTableInfo({ ...params })
        .then(({ data }) => {
          this.patientsInfo.patients_by_amount = {
            current_page: data.current_page,
            items: data.data,
            total: data.total,
          };
        })
        .finally(() => (this.patientsAmountTable = true));
    },
    loadScheduleCountPatientsTable() {
      const params = {
        doctor_id: this.doctorId,
        period: this.selectedPeriod,
        only_patients: true,
        type: 'schedules_count',
        paget: this.patientsInfo.patients_by_count.current_page,
      };

      DashboardService.getPatientsTableInfo({ ...params })
        .then(({ data }) => {
          this.patientsInfo.patients_by_count = {
            current_page: data.current_page,
            items: data.data,
            total: data.total,
          };
        })
        .finally(() => (this.patientsCountTable = true));
    },
    loadPatientsChartsInfos() {
      const params = {
        doctor_id: this.doctorId,
        period: this.selectedPeriod,
      };
      DashboardService.getPatientsChartsInfo({ ...params })
        .then(({ data }) => {
          this.patientsInfo.growing_by_date = data.growing_by_date;
          this.patientsInfo.created_by_date = data.created_by_date;
        })
        .finally(() => (this.patientsChartsInfo = true));
    },
    loadPatientsInfos() {
      const promises = [
        this.loadScheduleAmountPatientsTable(),
        this.loadScheduleCountPatientsTable(),
        this.loadPatientsChartsInfos(),
      ];

      Promise.all(promises).finally;
    },
    onPeriodFilterChange() {
      const period = this.periods.filter(
        (period) => period.id == this.periodFilter
      )[0];

      if (!period.isCustom) {
        this.selectedPeriod = `${period.start},${period.end}`;
        this.loadDashboardInfos();
      }
    },
    onCustomPeriodChange(values) {
      const dates = values.map((value) => moment(value).format('YYYY-MM-DD'));
      this.selectedPeriod = `${dates[0]},${dates[1]}`;

      this.loadDashboardInfos();
    },
    loadDashboardInfos() {
      this.isloadingScheduleInfos = true;
      this.patientsChartsInfo = false;
      this.patientsCountTable = false;
      this.patientsAmountTable = false;

      const promises = [this.loadSchedulesInfo(), this.loadPatientsInfos()];
      Promise.all(promises);
    },
  },
  mounted() {
    this.loadDashboardInfos();
  },
  beforeMount() {
    this.onPeriodFilterChange();
    this.isloadingScheduleInfos = true;
  },
};
</script>

<style lang="scss" scoped>
.dashboard-page {
  .custom-period-selector {
    width: 300px;
  }
}
</style>
