import Api from './api.service';
import groupBy from 'lodash/groupBy';

export default {
  get: (params = {}) =>
    Api.get('schedules', {
      params,
    }),
  getId: (id, params = {}) =>
    Api.get(`schedules/${id}`, {
      params,
    }),
  store: (data) => Api.post('schedules', data),
  getAvailabilities: () =>
    Api.get('schedules-availabilities').then(({ data }) =>
      groupBy(data, 'weekday')
    ),
  storeAvailability: (data) => Api.post('schedules-availabilities', data),
  deleteAvailability: (id) => Api.delete(`schedules-availabilities/${id}`),
  cancelSchedule: (id, data) =>
    Api.patch(`schedules/${id}/cancel`, {
      ...data,
    }),
  export: (params) => {
    return Api.get('schedules/export', { params });
  },
  getCheckouUrl: (scheduleId) => {
    return Api.get(`schedules/${scheduleId}/get-checkout-link`);
  },
  getScheduleList: (doctorId) => {
    return Api.get(`doctors/${doctorId}/schedule-list`);
  },
  getScheduleMeetRecords: (scheduleId) => {
    return Api.get(`schedules/${scheduleId}/meet-records`);
  },
};
