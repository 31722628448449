export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS';
export const AUTH_REQUEST_ERROR = 'AUTH_REQUEST_ERROR';

export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_TOKEN_SUCCESS = 'AUTH_TOKEN_SUCCESS';
export const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR';
export const AUTH_TOKEN_REFRESH = 'AUTH_TOKEN_REFRESH';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
