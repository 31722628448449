import Api from './api.service';

export default {
  storeRecord: (data) => Api.post('medical-records', data),
  getRecord: (recordId) => Api.get(`medical-records/${recordId}`),
  updateRecord: (id, data) => Api.put(`medical-records/${id}`, data),
  deleteRecord: (recordId) => Api.delete(`medical-records/${recordId}`),
  storeRecordModel: (data) => Api.post('medical-record-models', data),
  updateRecordModel: (modelId, data) =>
    Api.put(`medical-record-models/${modelId}`, data),
  getRecordModel: () => Api.get('medical-record-models?include=fieldModels'),
  getScheduleRecords: (scheduleId) =>
    Api.get(`medical-records?filter[schedule_id]=${scheduleId}`),
  getPatientMedicalRecords: (patientId) =>
    Api.get(`medical-records?filter[patient_id]=${patientId}`),
};
