<template>
  <div class="time-schedule-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Novo Horário</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="row mb-4" style="flex-direction: column">
          <div class="col-6 mt-3 mb-3">
            <time-selector v-model="schedule_standard_time"></time-selector>
          </div>
        </div>

        <div class="row" v-if="schedule_standard_time">
          <div class="col-5">
            <app-input
              type="tel"
              is-required
              label="Início"
              v-mask="'##:##'"
              placeholder="Informe o horário inicial"
              v-model="start"
              :errors="errors.start"
            ></app-input>
          </div>
          <div class="col-5">
            <app-input
              is-required
              label="Fim"
              v-mask="'##:##'"
              placeholder="Informe o horário final"
              v-model="end"
              :errors="errors.end"
            ></app-input>

            <div v-if="endShowError" id="schedule-standard-time-error">
              Atenção! Escolha um intervalo de início e fim onde se encaixe
              consultas de {{ schedule_standard_time }} minutos
            </div>
          </div>
        </div>
        <div class="row mt-4" style="flex-direction: column">
          <div class="col-6">
            <b-field label="Tipo da consulta" class="required">
              <b-select
                v-model="appointment_types"
                placeholder="Selecione o tipo"
              >
                <option :value="1" :key="1">Presencial</option>
                <option :value="2" :key="2">Teleconsulta</option>
                <option :value="[1, 2]" :key="3">Ambos</option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <weekdays-selector v-model="days"></weekdays-selector>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-6">
            <b-field label="Período Inicial">
              <b-datepicker
                v-model="date_start"
                :show-week-number="false"
                :locale="'pt-BR'"
                placeholder="Início"
                icon="calendar"
                :max-date="date_end"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="col-6">
            <b-field label="Período Final">
              <b-datepicker
                v-model="date_end"
                :show-week-number="false"
                :locale="'pt-BR'"
                placeholder="Fim"
                icon="calendar-alt"
                :min-date="date_start"
                trap-focus
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close')">Fechar</b-button>
        <b-button
          @click="save()"
          :loading="isSaving"
          :disabled="isDisabled || isSaving"
          type="is-primary"
        >
          Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';
import WeekdaysSelector from '@/components/WeekdaysSelector';
import ScheduleStandartTimeSelector from '@/components/ScheduleStandartTimeSelector';

import moment from 'moment';

export default {
  components: {
    WeekdaysSelector,
    'time-selector': ScheduleStandartTimeSelector,
  },
  data: () => ({
    isSaving: false,
    start: null,
    end: null,
    startError: '',
    endError: '',
    schedule_standard_time: null,
    appointment_types: null,
    days: [],
    timeRangeError: null,
    endShowError: false,
    date_start: null,
    date_end: null,
    errors: {
      start: [],
      end: [],
    },
  }),
  computed: {
    isDisabled() {
      return !this.start || !this.end || !this.days.length;
    },
    hasStartEnd() {
      return (
        this.start &&
        this.start.length === 5 &&
        this.end &&
        this.end.length === 5
      );
    },
  },
  watch: {
    start(newValue) {
      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');

      if (!hours || !minutes) return;

      if (Number(hours) < 0 || Number(hours) > 23)
        this.errors.start = ['Horário inválido!'];
      else if (Number(minutes) < 0 || Number(minutes) > 59)
        this.errors.start = ['Horário inválido!'];
      else this.errors.start = [];
    },
    end(newValue) {
      if (!newValue) return;

      const [hours, minutes] = newValue.split(':');

      if (!hours || !minutes) return;

      if (this.start > newValue) {
        this.errors.end = ['Horário não pode ser menor que o de início!'];
      } else if (Number(hours) < 0 || Number(hours) > 23)
        this.errors.end = ['Horário inválido!'];
      else if (Number(minutes) < 0 || Number(minutes) > 59)
        this.errors.end = ['Horário inválido!'];
      else this.errors.end = [];
    },
    hasStartEnd(newValue) {
      if (newValue) {
        const [startHour, startMinutes] = this.start.split(':');
        const [endHour, endMinutes] = this.end.split(':');

        const start = moment({
          day: new Date().getDay(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
          hour: startHour,
          minutes: startMinutes,
        });

        const end = moment({
          day: new Date().getDay(),
          month: new Date().getMonth(),
          year: new Date().getFullYear(),
          hour: endHour,
          minutes: endMinutes,
        });

        const start_time =
          parseInt(start.format('HH') * 60) + parseInt(start.format('mm'));
        const end_time =
          parseInt(end.format('HH') * 60) + parseInt(end.format('mm'));

        if ((end_time - start_time) % this.schedule_standard_time != 0) {
          this.end = null;
          this.endShowError = true;
        } else {
          this.endShowError = false;
        }
      }
    },
  },
  methods: {
    save() {
      this.isSaving = true;

      const {
        start,
        end,
        days,
        schedule_standard_time,
        appointment_types,
        date_start,
        date_end,
      } = this;

      const [startHour, startMinutes] = start.split(':');
      const [endHour, endMinutes] = end.split(':');

      const promises = days.map((weekday) =>
        SchedulesService.storeAvailability({
          start: moment({
            day: new Date().getDay(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            hour: startHour,
            minutes: startMinutes,
          }).format('HH:mm'),
          end: moment({
            day: new Date().getDay(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            hour: endHour,
            minutes: endMinutes,
          }).format('HH:mm'),
          schedule_standard_time,
          appointment_types,
          weekday,
          date_start,
          date_end,
        })
      );

      Promise.all(promises)
        .then((responses) => {
          const times = responses.map(({ data }) => data);
          this.$buefy.snackbar.open('Horários cadastrados com sucesso!');
          this.$emit('close', times);
        })
        .catch(() => {
          this.$buefy.snackbar.open(
            'Erro ao cadastrar um ou mais horários. Verifique se não há sobreposição.'
          );
          this.$emit('close');
        })
        .finally(() => (this.isSaving = false));

      this.isSaving = false;
    },
    canSetStandartTime() {
      return this.start && this.end;
    },
  },
};
</script>
<style lang="scss" scoped>
#schedule-standard-time-error {
  color: red;
}
</style>
