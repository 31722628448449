<template>
  <div class="medical-record-model-view">
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="table" title="Modelo Prontuário"></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <div class="fields-container">
            <draggable
              v-model="fields"
              group="medicalRecords"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                class="field"
                v-for="(field, idx) in fields"
                :key="field.order"
              >
                <dynamic-field
                  @update="onUpdate(idx, $event)"
                  :field="field"
                  class="field__item"
                ></dynamic-field>
                <b-button
                  @click="deleteField(idx)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </div>
            </draggable>
          </div>
          <div @click="addField()" class="add-field">
            <b-icon icon="plus" class="add-field__icon"></b-icon>
            <p class="add-field__label">Adicionar campo</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DynamicField from '@/components/DynamicField.vue';
import MedicalRecordModelService from '@/services/medical-records.service';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  components: {
    DynamicField,
    draggable,
  },
  data: () => ({
    key: Math.random(),
    recordModelId: null,
    isLoading: false,
    isDirty: false,
    fields: [],
  }),
  computed: {
    ...mapGetters(['secretaryId']),
  },
  methods: {
    addField() {
      this.fields.push({
        label: 'Label',
        field_model_type: null,
      });
    },
    deleteField(idx) {
      this.$delete(this.fields, idx);
    },
    onUpdate(idx, model) {
      this.$set(this.fields, idx, model);
    },
    submit() {
      this.recordModelId && this.updateModel();
      !this.recordModelId && this.saveModel();
    },
    saveModel() {
      if (!this.secretaryId) {
        MedicalRecordModelService.storeRecordModel(
          this.fields.map((model, idx) => {
            return { ...model, order: idx };
          })
        );
      }
    },
    updateModel() {
      if (!this.secretaryId) {
        MedicalRecordModelService.updateRecordModel(
          this.recordModelId,
          this.fields.map((model, idx) => {
            return { ...model, order: idx };
          })
        );
      }
    },
    loadMedicalRecordModel() {
      MedicalRecordModelService.getRecordModel().then(({ data: model }) => {
        if (!model) return;
        const { field_models } = model;
        this.recordModelId = model.id;

        this.fields = field_models.map((model) => {
          return {
            id: model.id,
            order: model.order,
            field_model_type: model.field_model_type,
            label: model.label,
          };
        });
      });
    },
  },
  beforeDestroy() {
    this.submit();
  },
  beforeMount() {
    this.loadMedicalRecordModel();
  },
};
</script>

<style lang="scss" scoped>
.fields-container {
  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
    padding: 8px;

    &:hover {
      background: #fafafa;
    }

    &__item {
      flex: 1;
      margin-right: 12px;
    }
  }
}

.add-field {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #f7f7f7;
  }
}
</style>
