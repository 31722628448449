import Clear from '@/layouts/Clear.vue';

export default {
  path: '/secretaries',
  component: Clear,
  children: [
    {
      path: '',
      name: 'secretaries.index',
      component: () => import('@/views/Secretaries/SecretaryList.vue'),
    },
    {
      path: 'create',
      name: 'secretaries.create',
      component: () => import('@/views/Secretaries/SecretaryForm.vue'),
    },
    {
      path: ':id/edit',
      name: 'secretaries.edit',
      component: () => import('@/views/Secretaries/SecretaryForm.vue'),
    },
  ],
};
