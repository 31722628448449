import Clear from '@/layouts/Clear.vue';

export default {
  path: '/digital-certificate',
  component: Clear,
  children: [
    {
      path: '',
      name: 'digital-certificate',
      component: () => import('@/views/DigitalCertificate.vue'),
    },
  ],
};
