<template>
  <div class="schedule-status">
    <div class="schedule-status__content">
      <b-tag
        v-if="schedule.rescheduled_to"
        rounded
        type="is-primary"
        size="is-small"
      >
        Reagendada pelo paciente para:
        {{ schedule.rescheduled_to | date('DD/MM/YYYY [às] HH:mm') }}
      </b-tag>

      <b-tag
        v-if="schedule.canceled_at && !schedule.rescheduled_to"
        rounded
        type="is-danger"
        size="is-small"
      >
        Consulta cancelada em:
        {{ schedule.canceled_at | date('DD/MM/YYYY [às] HH:mm') }}
      </b-tag>

      <b-tag
        v-if="schedule.canceled_at && !schedule.rescheduled_to"
        rounded
        type="is-dark"
        size="is-small"
      >
        Cancelada pelo(a):
        {{ schedule.canceled_by ? 'Paciente' : 'Médico(a)' }}
      </b-tag>

      <b-tag rounded type="is-secondary">
        Criada em:
        {{ schedule.created_at | date('DD/MM/YYYY [às] HH:mm') }}
      </b-tag>

      <b-tag rounded type="is-secondary">
        Marcada para:
        {{ schedule.start | date('DD/MM/YYYY [às] HH:mm') }}
      </b-tag>

      <b-tag rounded type="is-secondary"> Tipo: {{ scheduleType }} </b-tag>

      <b-tag rounded type="is-secondary">
        Criada internamente: {{ internalSchedule }}
      </b-tag>

      <b-tag rounded :type="`is-${schedule.schedule_status.type}`">
        Status: {{ schedule.schedule_status.label }}
      </b-tag>

      <b-tag rounded :type="`is-${schedule.payment_status.type}`">
        Status Pgto.: {{ schedule.payment_status.label }}
      </b-tag>

      <b-tag rounded type="is-success">
        Valor: {{ schedule.value | currency }}
      </b-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    scheduleType() {
      return this.schedule.appointment.appointment_type_id == 1
        ? 'Presencial'
        : 'Teleconsulta';
    },
    internalSchedule() {
      return this.schedule.internal == 1 ? 'Sim' : 'Não';
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-status {
  width: 100%;
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
