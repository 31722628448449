import Api from './api.service';

export default {
  get: (params = {}) => Api.get('secretaries', { params }),
  getId: (id) => Api.get(`secretaries/${id}`),
  store: (data) => Api.post('secretaries', data),
  update: (id, data) => Api.put(`secretaries/${id}`, data),
  destroy: (id) => Api.delete(`secretaries/${id}`),
  refresh: () => Api.get(`secretaries/refresh`),
};
