<template>
  <div class="cropper-container" v-if="!isLoading">
    <p class="message">
      Após selecionar a área para o recorte, clique na tesoura para finalizar.
    </p>

    <div class="cropper-wrapper">
      <div
        :style="{ backgroundImage: 'url(' + src + ')' }"
        class="image-background"
      ></div>
      <cropper
        ref="cropper"
        background-class="cropper-background"
        :src="src"
        :defaultSize="defaultSize"
        :image-restriction="'stencil'"
        :stencil-component="'circle-stencil'"
        :stencil-props="{
          previewClass: 'preview',
          aspectRatio,
          handlers: {},
          movable: false,
          scalable: false,
        }"
      ></cropper>
    </div>

    <div class="actions">
      <button @click="cancel" type="button">
        <b-icon size="is-small" icon="times"></b-icon>
      </button>
      <button @click="crop" type="button">
        <b-icon size="is-small" icon="cut"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';

export default {
  name: 'app-image-cropper',
  components: { Cropper },
  model: {
    prop: 'model',
    event: 'cropped',
  },
  props: {
    src: String,
    aspectRatio: Number,
    height: Number,
    width: Number,
  },
  data() {
    return {
      isLoading: true,
      result: null,
    };
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => this.setCroppedImage(blob));
    },
    cancel() {
      this.src = '';
      this.setCroppedImage('');
    },
    setCroppedImage(base64) {
      this.$emit('croppedImage', base64);
      this.isLoading = true;
    },
    defaultSize() {
      return {
        width: this.width || 600,
        height: this.height || 600,
      };
    },
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.preview {
  border: dashed 2px rgba(255, 255, 255, 0.8);
}
.cropper-container {
  .cropper-wrapper {
    overflow: hidden;
    position: relative;
    height: 400px;
    background: black;
  }
  .cropper-background {
    // background: none;
  }
  .image-background {
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    left: -10px;
    top: -10px;
    background-size: cover;
    background-position: 50%;
    filter: blur(5px);
  }

  .message {
    margin-top: 10px;
    margin-bottom: 5px;
    font-style: italic;
    color: var(--color-danger);
    background: none;
  }

  .actions {
    position: absolute;
    bottom: 0;

    button {
      cursor: pointer;
      color: #fff;
      outline: none;
      border: none;
      width: 40px;
      height: 28px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    button:first-child {
      background: var(--color-danger);

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    button:last-child {
      background: var(--color-primary);

      margin-left: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    button:last-child:hover {
      background: var(--color-secondary);
    }

    button:first-child:hover {
      background: var(--color-secondary);
    }
  }
}
</style>
