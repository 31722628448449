<template>
  <div id="app">
    <loading-page v-if="isLoading"></loading-page>
    <router-view v-if="!isLoading" />
  </div>
</template>

<script>
import LoadingPage from '@/views/Loading.vue';
import { AUTH_TOKEN } from '@/store/actions/auth';
import { mapGetters } from 'vuex';

export default {
  components: {
    LoadingPage,
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.loading;
    },
    ...mapGetters(['secondStage', 'complete', 'secretaryId']),
  },
  mounted() {
    this.$store
      .dispatch(AUTH_TOKEN)
      .then(() => {
        if ((!this.secondStage || !this.complete) && !this.secretaryId)
          this.$router.push('/doctor-profile');
        else this.$router.push('/schedule');
      })
      .catch(() => {
        switch (this.$route.name) {
          case 'login':
          case 'register': {
            this.$router.push(this.$route.fullPath);
            break;
          }
          default:
            this.$router.push('/login');
        }
      });
  },
};
</script>

<style lang="scss">
@import 'styles/_global.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
</style>
