<template>
  <div>
    <div class="appointment-view">
      <app-header icon="dollar-sign" title="Valores"></app-header>

      <section>
        <div class="card">
          <b-loading
            :active.sync="isAppointmentLoading"
            :is-full-page="false"
          ></b-loading>
          <div class="card-content">
            <form>
              <div class="row">
                <div class="col-4">
                  <app-input
                    label="Consulta presencial"
                    v-model="values[AppointmentTypeEnum.INTERNAL]"
                    type="text"
                    :mask="MASKS.money"
                    :errors="errors.internal.value"
                    is-required
                  ></app-input>
                </div>

                <div class="col-4">
                  <app-input
                    label="Teleconsulta"
                    v-model="values[AppointmentTypeEnum.EXTERNAL]"
                    type="text"
                    :mask="MASKS.money"
                    :errors="errors.external.value"
                    is-required
                  ></app-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12 has-text-right d-flex">
                  <b-button
                    @click="save()"
                    :loading="isLoading"
                    :disabled="isLoading || !isValid"
                    type="is-primary"
                    class="mt-auto"
                  >
                    Salvar
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
    <div class="procedure-list-view">
      <app-header icon="procedures" title="Meus Procedimentos"></app-header>

      <section>
        <div class="card">
          <header class="card-header">
            <div class="card-header-content">
              <div class="row">
                <div class="col-6">
                  <app-input
                    @input="onTermChange($event)"
                    placeholder="Buscar..."
                    label="Filtrar por nome"
                    icon="search"
                  >
                  </app-input>
                </div>
              </div>
            </div>
            <div class="card-header-buttons">
              <router-link :to="{ name: 'procedures.create' }">
                <b-tooltip label="Novo Procedimento">
                  <b-button type="is-primary" size="is-small" icon-left="plus">
                    Adicionar
                  </b-button>
                </b-tooltip>
              </router-link>
            </div>
          </header>

          <div class="card-content">
            <b-table
              @page-change="onPageChange"
              :data="procedures.data"
              :total="procedures.total"
              :per-page="15"
              :loading="isProceduresLoading"
              paginated
              backend-pagination
              aria-next-label="Próxima Página"
              aria-previous-label="Página Anterior"
              aria-page-label="Página"
              aria-current-label="Página Atual"
            >
              <b-table-column v-slot="props" field="patient" label="Nome">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column v-slot="props" field="date" label="Valor">
                {{ props.row.value | currency }}
              </b-table-column>

              <b-table-column v-slot="props" label="Opções">
                <router-link
                  :to="{
                    name: 'procedures.edit',
                    params: { id: props.row.id },
                  }"
                >
                  <b-tooltip label="Editar">
                    <b-button
                      type="is-secondary"
                      size="is-small"
                      icon-right="pencil-alt"
                    />
                  </b-tooltip>
                </router-link>
                <b-tooltip label="Excluir">
                  <b-button
                    @click="onClickDelete(props.row.id)"
                    type="is-danger"
                    size="is-small"
                    icon-right="trash"
                    class="ml-2"
                  />
                </b-tooltip>
              </b-table-column>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="frown" size="is-large"></b-icon>
                    </p>
                    <p>Nenhum procedimento encontrado.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AppointmentService from '@/services/appointments.service';
import AppointmentTypeEnum from '@/enums/AppointmentTypeEnum';
import MASKS from '@/constants/masks.constant';
import ProceduresService from '@/services/procedures.service';

export default {
  data: () => ({
    MASKS: MASKS,
    isAppointmentLoading: false,
    isLoading: false,
    isProceduresLoading: false,
    AppointmentTypeEnum: AppointmentTypeEnum,
    procedures: {
      data: [],
      page: 1,
      total: 0,
      term: '',
    },
    values: {
      [AppointmentTypeEnum.INTERNAL]: 0,
      [AppointmentTypeEnum.EXTERNAL]: 0,
    },
    errors: { external: { value: null }, internal: { value: null } },
  }),
  computed: {
    isValid() {
      return !Object.values(this.values).find((el) => !el);
    },
  },

  methods: {
    save() {
      this.isAppointmentLoading = true;

      const data = [
        {
          value: this.unmask(this.values[AppointmentTypeEnum.INTERNAL]),
          appointment_type_id: AppointmentTypeEnum.INTERNAL,
        },
        {
          value: this.unmask(this.values[AppointmentTypeEnum.EXTERNAL]),
          appointment_type_id: AppointmentTypeEnum.EXTERNAL,
        },
      ];

      Promise.all(data.map((app) => AppointmentService.store(app)))
        .then(() => {
          this.errors.internal.value = null;
          this.errors.external.value = null;
          this.$buefy.snackbar.open('Valores salvos com sucesso.');
        })
        .catch(({ response }) => {
          const internalValue = this.unmask(
            this.values[AppointmentTypeEnum.INTERNAL]
          );
          const externalValue = this.unmask(
            this.values[AppointmentTypeEnum.EXTERNAL]
          );

          if (internalValue < 15) this.errors.internal = response.data.message;
          if (externalValue < 15) this.errors.external = response.data.message;

          this.$buefy.snackbar.open('Erro ao tentar salvar valores.');
        })
        .finally(() => (this.isAppointmentLoading = false));
    },
    loadAppointments() {
      this.isAppointmentLoading = true;

      AppointmentService.get()
        .then(({ data: appointments }) => {
          appointments.map((app) => {
            this.values[app.appointment_type_id] = Number(
              app.value
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
          });
        })
        .finally(() => (this.isAppointmentLoading = false));
    },
    unmask(value) {
      if (!value) return value;

      const realValue = value.split('R$').pop();
      return realValue.trim().replaceAll('.', '').replace(',', '.');
    },
    loadProcedures() {
      this.isProceduresLoading = true;

      const params = {
        page: this.procedures.page,
        'filter[name]': this.procedures.term,
      };

      ProceduresService.get({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.procedures = { total, data: items, page: current_page };
        })
        .finally(() => (this.isProceduresLoading = false));
    },
    onPageChange(page) {
      this.procedures.page = page;
      this.loadProcedures();
    },
    onTermChange(value) {
      this.procedures.page = 1;
      this.procedures.term = value;
      this.loadProcedures();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        message:
          'Deseja realmente excluir este procedimento? Essa ação não poderá ser desfeita.',
        onConfirm: () => this.deleteProcedure(id),
      });
    },
    deleteProcedure(id) {
      this.isLoading = true;

      ProceduresService.destroy(id)
        .then(({ data: procedureId }) => {
          this.$buefy.snackbar.open('Procedimento excluído com sucesso.');
          this.procedures.data = this.procedures.data.filter(
            (procedure) => procedure.id !== procedureId
          );
        })
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao tentar excluir procedimento.')
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadAppointments();
    this.loadProcedures();
  },
};
</script>
