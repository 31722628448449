export default [
  {
    icon: 'home',
    label: 'Dashboard',
    routeName: 'dashboard',
  },
  {
    icon: 'calendar',
    label: 'Agenda',
    routeName: 'schedule',
  },
  {
    icon: 'clock',
    label: 'Meus Horários',
    routeName: 'timetable',
  },
  {
    icon: 'clipboard',
    label: 'Consultas',
    routeName: 'schedules.index',
  },
  {
    icon: 'table',
    label: 'Modelo Prontuário',
    routeName: 'medical-records',
  },
  {
    icon: 'table',
    label: 'Modelos de Receituário',
    routeName: 'prescription-models.index',
  },
  {
    icon: 'users',
    label: 'Pacientes',
    routeName: 'patients.index',
  },
  {
    icon: 'dollar-sign',
    label: 'Valores',
    routeName: 'appointments',
  },
  {
    icon: 'ticket-alt',
    label: 'Vouchers',
    routeName: 'discounts.index',
  },
  {
    icon: 'female',
    label: 'Secretárias',
    routeName: 'secretaries.index',
  },
  {
    icon: 'user',
    label: 'Perfil',
    routeName: 'doctor-profile',
  },
  {
    icon: 'user',
    label: 'Perfil',
    routeName: 'secretary-profile',
  },
  {
    icon: 'coins',
    label: 'Financeiro',
    routeName: 'financial',
  },
  {
    icon: 'file-invoice',
    label: 'Certificado Digital',
    routeName: 'digital-certificate',
  },
];
