import Api from './api.service';

const ENDPOINT = 'doctors';

export default {
  put: (data, id) => Api.put(`${ENDPOINT}/${id}`, data),
  patch: (data, id) => Api.patch(`${ENDPOINT}/${id}`, data),
  getId: (id) => Api.get(`${ENDPOINT}/${id}`),
  refresh: () => Api.get(`${ENDPOINT}/refresh`),
  setPhoto: (doctorId, file, type, junoFile = 0) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append('juno_file', junoFile);

    return Api.post(`${ENDPOINT}/${doctorId}/set-photo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  getAppointmentInfo(doctorId) {
    return Api.get(`appointments?filter[doctor_id]=${doctorId}`);
  },
  getBalanceInfo() {
    return Api.get(`${ENDPOINT}/balance-info`);
  },
  getDoctorTransactions(params = {}) {
    return Api.get('doctors-transactions', { params });
  },
  transferBalance(amount) {
    return Api.post(`${ENDPOINT}/transfer-balance`, { amount });
  },
  getCertificate() {
    return Api.get(`${ENDPOINT}/digital-certificate`);
  },
  storeCertificate(data) {
    const formData = new FormData();

    formData.append('file', data.file ?? '');
    formData.append('password', data.password ?? '');

    return Api.post(`${ENDPOINT}/digital-certificate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  deleteCertificate(certificateId) {
    return Api.delete(`${ENDPOINT}/${certificateId}/digital-certificate`);
  },
};
