import Api from './api.service';

const ENDPOINT = 'prescriptions';

export default {
  get: (scheduleId) => Api.get(`${ENDPOINT}?filter[schedule_id]=${scheduleId}`),
  getPatientPrescriptions: (patientId) =>
    Api.get(`${ENDPOINT}?filter[patient_id]=${patientId}`),
  store: (data) => Api.post(`${ENDPOINT}`, data),
  update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),

  getModel: (id) => Api.get(`prescription-models/${id}`),
  getModels: (params = {}) => Api.get('prescription-models', { params }),
  storeModel: (data) => Api.post('prescription-models', data),
  updateModel: (id, data) => Api.put(`prescription-models/${id}`, data),
  destroyModel: (id) => Api.delete(`prescription-models/${id}`),

  downloadPrescription: (id) => Api.get(`${ENDPOINT}/${id}/download-pdf`),
  sendPrescriptionToPatient: (id) =>
    Api.get(`${ENDPOINT}/${id}/send-to-patient`),
  getPrescriptionFileModels: () => Api.get('prescription-file-models'),
  saveMemedPrescription: (data) =>
    Api.post(`${ENDPOINT}/save-memed-prescription`, data),
};
