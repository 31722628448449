import Clear from '@/layouts/Clear.vue';

export default {
  path: '/patients',
  component: Clear,
  children: [
    {
      path: '',
      name: 'patients.index',
      component: () => import('@/views/Patients/PatientList.vue'),
    },
    {
      path: 'create',
      name: 'patients.create',
      component: () => import('@/views/Patients/PatientForm.vue'),
    },
    {
      path: ':id/edit',
      name: 'patients.edit',
      component: () => import('@/views/Patients/PatientForm.vue'),
    },
  ],
};
