<template>
  <section class="login-page">
    <div class="login-page__info-container">
      <div class="info-text">
        <p>
          Bem-vindo à área de login do Best Doctors App, uma plataforma de
          gestão das consultas médicas através da telemedicina e também na forma
          presencial, exclusivo para especialistas credenciados com RQE.
        </p>
        <router-link :to="{ name: 'register' }">
          <b-button type="is-text" class="mt-2 register-action" rounded>
            Quero me cadastrar
          </b-button>
        </router-link>
      </div>
    </div>
    <div class="login-page__form-container">
      <div class="form">
        <div class="header">
          <div v-if="!isPasswordReset">
            <h1>Login</h1>
            <p>Entre com e-mail e senha</p>
          </div>
          <div v-if="isPasswordReset">
            <h1>Esqueci minha senha</h1>
            <p>
              Será enviado um e-mail para o endereço inserido com os próximos
              passos
            </p>
          </div>
        </div>
        <form v-if="!isPasswordReset" @submit.prevent="login">
          <b-field>
            <b-input
              v-model="email"
              custom-class="center-text"
              placeholder="E-mail"
              type="email"
              autocomplete="false"
            ></b-input>
          </b-field>
          <b-field>
            <b-input
              v-model="password"
              custom-class="center-text"
              placeholder="Senha"
              type="password"
            ></b-input>
          </b-field>

          <div class="actions">
            <b-button
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5 login-button"
              expanded
              rounded
            >
              Entrar
            </b-button>

            <a
              @click="isPasswordReset = !isPasswordReset"
              class="recovery-action"
            >
              Esqueceu sua senha? Clique aqui
            </a>
          </div>

          <router-link :to="{ name: 'register' }" class="form-register-action">
            <b-button type="is-text" expanded rounded>
              Quero me cadastrar
            </b-button>
          </router-link>
        </form>

        <form v-if="isPasswordReset" @submit.prevent="requestPasswordReset">
          <b-field>
            <b-input
              v-model="email"
              custom-class="center-text"
              placeholder="E-mail"
              type="email"
              autocomplete="false"
            ></b-input>
          </b-field>

          <div class="actions">
            <b-button
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5 login-button"
              expanded
              rounded
            >
              Enviar
            </b-button>

            <a
              @click="isPasswordReset = !isPasswordReset"
              class="recovery-action"
            >
              Fazer Login
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { AUTH_REQUEST, AUTH_LOGOUT } from '@/store/actions/auth';
import MobileAccess from '@/modals/MobileAccess.vue';
import UserService from '@/services/user.service';
import jwt_decode from 'jwt-decode';
import { mapGetters } from 'vuex';

import { isMobile, isTablet, isAndroid } from 'mobile-device-detect';

import * as Movidesk from '../scripts/movidesk';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default {
  data: () => ({
    email: null,
    password: null,
    isLoading: false,
    isPasswordReset: false,
  }),
  computed: {
    ...mapGetters(['readOnly', 'secondStage', 'complete']),
  },
  methods: {
    login() {
      const { email, password } = this;
      this.isLoading = true;

      if (isMobile || isTablet) {
        this.$buefy.modal.open({
          parent: this,
          component: MobileAccess,
          hasModalCard: true,
          trapFocus: true,
          events: {
            close: (data) => {
              if (!data) return;

              isAndroid &&
                window.open(
                  'https://play.google.com/store/apps/details?id=com.bestdoctorsmed.app',
                  '_blank'
                );

              !isAndroid &&
                window.open(
                  'https://apps.apple.com/us/app/bdoctors-web/id1558159135'
                );
            },
          },
        });

        return;
      }

      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then((token) => {
          if (isMobile || isTablet) {
            this.$store.dispatch(AUTH_LOGOUT);
            this.isLoading = false;

            this.$buefy.modal.open({
              parent: this,
              component: MobileAccess,
              hasModalCard: true,
              trapFocus: true,
            });

            return;
          }

          const { secretary_id, permissions } = jwt_decode(token);

          if (secretary_id) {
            const menu_order = [
              'schedule',
              'schedules.index',
              'patients.index',
            ];
            let to_push = '';

            menu_order.forEach(function (menu) {
              if (permissions.indexOf(menu) > -1 && to_push == '') {
                to_push = menu.replace('.index', '');
              }
            });

            to_push != ''
              ? this.$router.push('/' + to_push)
              : this.$router.push('/secretary-profile');
          } else {
            const page =
              this.readOnly || !this.secondStage || !this.complete
                ? 'doctor-profile'
                : 'schedule';

            this.$router.push({ name: page });
          }

          Movidesk.initMoviDesk();
        })
        .catch(({ response }) => {
          const { message } = response.data;

          this.isLoading = false;
          this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            position: 'is-bottom',
          });
        });
    },
    requestPasswordReset() {
      const { email } = this;
      this.isLoading = true;

      UserService.requestPasswordReset(email)
        .then(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.message,
            type: 'is-success',
            position: 'is-bottom',
          });
          this.isPasswordReset = !this.isPasswordReset;
        })
        .catch(({ response }) => {
          const { message } = response.data;
          this.$buefy.snackbar.open({
            message,
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background: #fff;

  height: 100vh;
  overflow: hidden;

  &__info-container {
    display: flex;
    align-items: center;

    padding: 2%;

    .info-text {
      position: relative;
      top: 15vh;

      p {
        font-size: 1.6rem;
        color: #fff;
      }
    }

    .info-action {
      position: relative;
      top: 23vh;
    }
  }

  &__form-container {
    .header {
      margin-bottom: 30px;
      text-align: center;
      color: #47a5d7;

      h1 {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        font-style: italic;
        font-family: serif;
        font-size: 0.9rem;
      }
    }

    font-family: 'Poppins';

    .actions {
      margin-top: 15px;
      margin-bottom: 70px;
      text-align: center;

      button {
        margin-bottom: 20px;
      }

      .recovery-action {
        color: #47a5d7;
        font-size: 0.9rem;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  * {
    font-size: 16px;
  }

  .login-page {
    display: flex;
    justify-content: center;
    position: relative;

    height: calc(var(--vh, 1vh) * 100);

    &::before {
      content: '';

      opacity: 0.2;
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: url('~@/assets/img/detail.png');
      background-position: 0 15vh;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: #fff;
    }

    &__info-container {
      display: none;
    }

    &__form-container {
      width: 80%;
      position: relative;
      top: 15vh;
    }
  }
}

@media screen and (min-width: 760px) {
  .login-page {
    &::before {
      background-position: 5vw 11vh;
      background-size: 90%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .login-page {
    display: flex;

    &__info-container {
      width: 70%;

      background: url('~@/assets/img/background.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #fff;

      .info-text {
        .register-action {
          float: right;

          border: 2px solid var(--color-primary);

          color: #fff;

          text-decoration: none;
          background-color: transparent;
        }
      }
    }

    &__form-container {
      width: 30%;
      padding: 3%;

      background: url('~@/assets/img/detail.png');
      background-position: 10vw 105%;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #fff;

      .header {
        h1 {
          font-size: 1.4rem;
        }
      }

      .actions {
        .recovery-action {
          font-size: 0.8rem;
        }
      }

      .form-register-action {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .login-page {
    &__info-container {
      width: 75%;
      justify-content: center;

      .info-text {
        width: 80%;
        p {
          font-size: 1.8rem;
        }
      }
    }

    &__form-container {
      width: 25%;

      .header {
        h1 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
