<template>
  <div class="financial-view">
    <app-header icon="coins" title="Financeiro"></app-header>

    <section>
      <div class="card">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
        <div class="card-content">
          <div class="row mt-2">
            <div class="col-3">
              <app-input
                readonly
                type="text"
                label="Saldo"
                icon="dollar-sign"
                tooltipLabel="Saldo à liberar mais saldo de transferência"
                :value="fixedValue('balance')"
              ></app-input>
            </div>
            <div class="col-3">
              <app-input
                readonly
                type="text"
                label="Saldo a liberar"
                icon="dollar-sign"
                :value="fixedValue('withheld')"
              ></app-input>
            </div>
            <div class="col-3">
              <app-input
                readonly
                type="text"
                label="Saldo transferível"
                icon="dollar-sign"
                :value="fixedValue('transferable')"
              ></app-input>
            </div>
          </div>

          <div class="row mt-4 mb-5">
            <div class="col-12">
              <b-button
                @click="onTransferClick"
                :disabled="isLoading"
                type="is-primary"
              >
                Transferir para conta bancária
              </b-button>
            </div>
          </div>

          <div class="col-12 mt-4">
            <div class="card">
              <div class="card-header">
                <div class="card-header-title">Histórico de transferências</div>
              </div>
              <div class="card-content">
                <b-table
                  @page-change="onPageChange"
                  :per-page="perPage"
                  :loading="is_loading_transactions"
                  :total="total"
                  :data="transactions_data"
                  paginated
                  backend-pagination
                  aria-page-label="Página"
                  aria-next-label="Próxima Página"
                  aria-current-label="Página Atual"
                  aria-previous-label="Página Anterior"
                >
                  <b-table-column v-slot="props" field="amount" label="Valor">
                    {{ '$' + parseFloat(props.row.amount).toFixed(2) }}
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="date"
                    label="Data/Horário"
                  >
                    {{ props.row.created_at | date('DD/MM/YYYY [às] HH:mm') }}
                  </b-table-column>

                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="frown" size="is-large"></b-icon>
                        </p>
                        <p>Nenhuma transferência encontrada.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DoctorService from './../services/doctor.service';
import BalanceTransfer from '@/modals/BalanceTransfer.vue';

export default {
  data: () => ({
    isLoading: false,
    is_loading_transactions: false,
    transactions_data: [],
    total: 0,
    page: 1,
    perPage: 10,
    balance_info: {
      balance: null,
      withheld: null,
      transferable: null,
    },
  }),
  watch: {
    perPage() {
      if (this.perPage > this.transactions_data.length) this.loadPatients();
    },
  },

  methods: {
    onPageChange(page) {
      this.page = page;
      this.loadDoctorTransactions();
    },

    loadBalanceInfo() {
      this.isLoading = true;
      DoctorService.getBalanceInfo()
        .then(({ data }) => {
          this.balance_info = { ...data };
        })
        .finally(() => (this.isLoading = false));
    },

    loadDoctorTransactions() {
      const params = {
        page: this.page,
        perPage: this.perPage,
        doctor_id: this.$store.getters.doctorId,
      };
      this.is_loading_transactions = true;

      DoctorService.getDoctorTransactions({ ...params })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;

          this.total = total;
          this.page = current_page;
          this.transactions_data = Object.values(items);
        })
        .finally(() => (this.is_loading_transactions = false));
    },

    onTransferClick() {
      this.$buefy.modal.open({
        parent: this,
        component: BalanceTransfer,
        hasModalCard: true,
        trapFocus: true,
        props: {
          transferableValue: this.balance_info.transferable,
        },
        events: {
          close: (data) => data && this.transferBalance(data.value),
        },
      });
    },
    transferBalance(value) {
      this.isLoading = true;
      DoctorService.transferBalance(value)
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.loadBalanceInfo();
          this.loadDoctorTransactions();
        })
        .catch(({ response }) => {
          this.$buefy.snackbar.open({
            message: response.data.message,
            type: 'is-danger',
          });
        })
        .finally(() => (this.isLoading = false));
    },
    fixedValue(key) {
      return this.balance_info[key]
        ? this.balance_info[key].toFixed(2)
        : (0).toFixed(2);
    },
  },
  beforeMount() {
    this.loadBalanceInfo();
    this.loadDoctorTransactions();
  },
};
</script>

<style lang="scss"></style>
