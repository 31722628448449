<template>
  <div class="doctor-profile-view">
    <app-header icon="user" title="Seus dados"></app-header>

    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <div class="form-container">
      <form @submit.prevent="onSubmit" v-if="!isLoading">
        <div class="row">
          <div class="col-4">
            <app-input
              label="Nome"
              v-model="secretary.name"
              type="text"
              :errors="errors.name"
              is-required
            ></app-input>
          </div>

          <div class="col-4">
            <app-input
              label="E-mail"
              v-model="secretary.user.email"
              type="email"
              autocomplete="email"
              :errors="errors.email"
              is-required
            ></app-input>
          </div>
          <div class="col-4">
            <app-input
              label="Senha"
              v-model="secretary.password"
              type="password"
              :errors="errors.password"
              autocomplete="new-password"
            ></app-input>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 button-container">
            <b-button
              @click="onSubmit"
              :loading="isLoading"
              :disabled="isLoading"
              type="is-primary"
            >
              Salvar
            </b-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SecretariesService from '@/services/secretaries.service';
import flattenDeep from 'lodash/flattenDeep';

export default {
  props: {
    isModal: Boolean,
  },
  data: () => ({
    isLoading: false,
    secretaryId: Number,
    errors: {},
    secretary: { user: { permissions: [] } },
  }),
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.errors = {};

      const data = {
        name: this.secretary.name,
        email: this.secretary.user.email,
        password: this.secretary.password,
      };

      SecretariesService.update(this.secretaryId, data)
        .then(() => {
          this.$buefy.snackbar.open('Secretária salva com sucesso.');
        })
        .catch(({ response }) => {
          this.isSaving = false;
          const { status, data } = response;

          if (status === 422) this.errors = flattenDeep(data);
          else
            this.$buefy.snackbar.open(
              data.message || 'Erro ao tentar salvar secretária.'
            );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadSecretary() {
      this.isLoading = true;

      SecretariesService.getId(this.secretaryId)
        .then(({ data }) => {
          this.secretary = {
            ...data,
          };
        })
        .catch(() => this.$buefy.snackbar.open('Erro ao carregar secretária.'))
        .finally(() => (this.isLoading = false));
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.secretaryId = this.$store.getters.secretaryId;
    this.loadSecretary();
  },
};
</script>

<style lang="scss" scoped>
.alert-message {
  color: var(--color-danger);
}

.form-container {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 10px -4px lightgrey;
  padding: 24px;

  .picture-preview {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
}

form {
  .row:not(:first-child) > div[class^='col-'] {
    margin-top: 12px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
