import * as moment from 'moment';

export default [
  {
    id: 1,
    name: 'Um mês',
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  },
  {
    id: 2,
    name: 'Três meses',
    start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    id: 3,
    name: 'Seis meses',
    start: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    id: 4,
    name: 'Um ano',
    start: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    id: 5,
    name: 'Dois anos',
    start: moment().subtract(2, 'year').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  {
    id: 6,
    name: 'Personalizado',
    start: null,
    end: null,
    isCustom: true,
  },
];
