<template>
  <div class="dashboard-layout">
    <section :class="'menu-container ' + pinMenuClass">
      <b-sidebar position="static" type="is-white" open>
        <div @click="pinMenu = !pinMenu" class="pin-container">
          <b-icon
            class="pin"
            size="is-small"
            :type="pinMenu ? 'is-primary' : 'is-dark'"
            icon="thumbtack"
          ></b-icon>
        </div>
        <div :class="pinMenu ? 'fixed-menu logo-ctn' : 'logo-ctn'">
          <div>
            <img :src="require('@/assets/img/logo.png')" alt="" srcset="" />
          </div>
          <div class="logged-name">
            <span>{{ userLoggedName }}</span>
          </div>
        </div>
        <b-menu :activable="false">
          <b-menu-list label="Menu" type="is-primary">
            <router-link
              tag="div"
              v-for="(menu, index) in menus"
              :key="index"
              :to="{ name: menu.routeName }"
              :exact="menu.routeName === 'index'"
            >
              <b-menu-item :icon="menu.icon" :label="menu.label"></b-menu-item>
            </router-link>
          </b-menu-list>
          <b-menu-list label="Ações">
            <router-link
              tag="div"
              :to="{ name: 'contact-us' }"
              :exact="'contact-us' === 'index'"
            >
              <b-menu-item
                icon="user-friends"
                label="Fale conosco"
              ></b-menu-item>
            </router-link>
            <b-menu-item
              @click="onLogoutClick()"
              icon="sign-out-alt"
              label="Sair"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </b-sidebar>
    </section>
    <section class="router-container custom-scroll" ref="router">
      <router-view @calendar_loaded="calendarLoaded"></router-view>
    </section>
  </div>
</template>

<script>
import MENUS from '@/constants/menu.constant';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    menus: MENUS,
    pinMenu: false,
  }),
  computed: {
    ...mapGetters([
      'secondStage',
      'readOnly',
      'userName',
      'secretaryId',
      'secretaryName',
      'complete',
    ]),
    pinMenuClass() {
      return this.pinMenu ? 'fixed' : '';
    },
    userLoggedName() {
      return this.secretaryId
        ? `Olá, ${this.secretaryName.split(' ')[0]}`
        : `Olá, Dr(a) ${this.userName.split(' ')[0]}`;
    },
  },

  methods: {
    onLogoutClick() {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente sair?',
        onConfirm: () => this.logout(),
      });
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
    },
    calendarLoaded() {
      this.$nextTick(() => {
        this.$refs.router.scrollTop = 235;
      });
    },
  },
  mounted() {
    if (this.secretaryId) {
      this.menus = MENUS.filter((menu) => menu.routeName != 'doctor-profile');
    } else {
      this.menus = MENUS.filter(
        (menu) => menu.routeName != 'secretary-profile'
      );
    }

    if (
      !this.secretaryId &&
      (this.readOnly || !this.secondStage || !this.complete)
    ) {
      this.menus = MENUS.filter((menu) => menu.routeName === 'doctor-profile');
    }
    if (typeof this.$store.getters.permissions != 'undefined') {
      this.menus = this.menus.filter((menu) => {
        if (menu.routeName == 'secretary-profile') return menu;
        if (this.$store.getters.permissions.indexOf(menu.routeName) > -1)
          return menu;
      });
    }
  },
};
</script>

<style lang="scss">
div.dashboard-layout {
  display: flex;
  height: 100vh;
  background: var(--color-secondary);

  section.menu-container {
    height: 100vh;
    width: 41px;
    overflow-y: scroll;
    transition: width 1s;
    overflow: hidden;
    background: var(--color-secondary);

    .sidebar-content {
      height: 100vh;

      background: var(--color-secondary);
      box-shadow: 0 2px 16px -4px rgba(0, 0, 0, 0.5);

      .pin-container {
        .pin {
          position: relative;
          top: 1vh;
          left: 240px;
          z-index: 2;
          cursor: pointer;
        }
        direction: ltr;
      }

      .logo-ctn {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          width: 140px;
        }

        padding: 1rem 0;

        position: relative;
        top: -30px;

        background: var(--color-secondary);
        margin-bottom: 15px;
        transition-delay: 0.5s;

        .salute {
          font-size: 0.9em;
          font-weight: 500;
        }

        .logged-name {
          width: 100%;
          text-align: center;
          font-size: 0.9rem;
          font-family: 'Poppins';
          font-weight: 600;

          color: var(--color-secondary);
        }
      }

      .fixed-menu {
        background: #f9f9f9;
      }

      .menu-label {
        padding-left: 0.6rem;
        display: none;
        transition-delay: 15s;
        color: #fff;
      }

      div.router-link-active {
        > li a {
          background: #fff;
          color: #000;
          font-weight: 600;
        }
      }

      div a:hover {
        background: var(--color-primary);
      }

      li a {
        padding: 0.8rem;
        color: #fff;

        span:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }

  section.menu-container:hover {
    width: 260px;
    transition: width 0.5s ease-in-out;
    direction: rtl;

    .menu-label {
      display: block;
      transition-duration: 15s;
      color: #fff;
      direction: ltr;
    }

    .logo-ctn {
      background: #f9f9f9;
      transition-delay: 0.2s;
    }

    .menu-list {
      direction: ltr;
    }
  }

  section.menu-container.fixed {
    width: 260px;
    direction: rtl;

    .menu-label {
      display: block;
      direction: ltr;
    }

    .menu-list {
      direction: ltr;
    }
  }

  section.router-container {
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    background: #f9f9f9;
    padding: 1.4rem;
  }
}

@media only screen and (min-width: 1280px) {
  div.dashboard-layout {
    section.menu-container {
      overflow: hidden;

      .sidebar-content {
        height: 110vh;

        .pin {
          top: 2vh;
        }
      }
    }

    section.menu-container:hover {
      overflow-y: auto;
      .menu-label {
        margin-left: 5px;
      }
      .menu-list {
        margin-left: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar {
        width: 8px;

        background-color: #f7f5f5;
      }
    }

    section.menu-container.fixed {
      overflow-y: auto;
      .menu-label {
        margin-left: 5px;
      }
      .menu-list {
        margin-left: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar {
        width: 8px;

        background-color: #f7f5f5;
      }
    }
  }
}
</style>
