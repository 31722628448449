<template>
  <div class="timetable-view">
    <app-header icon="clock" title="Meus Horários"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-title">
            <div class="caption">
              <div class="caption__item">
                <div class="color apt-1"></div>
                <p>Presencial</p>
              </div>
              <div class="caption__item">
                <div class="color apt-2"></div>
                <p>Teleconsulta</p>
              </div>
              <div class="caption__item">
                <div class="color apt-1 apt-2"></div>
                <p>Ambos</p>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Novo Horário">
              <b-button
                @click="newTime()"
                type="is-primary"
                size="is-small"
                icon-left="plus"
              >
                Adicionar
              </b-button>
            </b-tooltip>
          </div>
        </header>
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <no-content
            v-if="isEmpty"
            text="Nenhum horário cadastrado."
            icon="clock"
          ></no-content>
          <div class="weekdays-container">
            <div
              v-for="(day, weekday) in days"
              :key="`d${weekday}`"
              class="weekday"
            >
              <div class="weekday__title">
                <p>{{ weekdays[weekday] | titleCase }}</p>
              </div>
              <div class="weekday__times custom-scroll">
                <ol>
                  <li
                    v-for="(time, idx) in day"
                    :key="`t${idx}`"
                    :class="getTimeClass(time)"
                  >
                    {{ time.start | time }} - {{ time.end | time }}
                    {{ `(${time.schedule_standard_time} min)` }}
                    <br />
                    {{ formatDate(time.date_start, time.date_end) }}
                    <b-button
                      @click="deleteTime(time)"
                      type="is-light"
                      icon-left="times"
                      size="is-small"
                    ></b-button>
                  </li>
                </ol>
              </div>
              <div class="weekday__clear">
                <b-button
                  @click="onClearClick(weekday)"
                  :disabled="!day.length"
                  type="is-text"
                  size="is-small"
                >
                  Limpar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';
import TimeSchedule from '@/modals/TimeSchedule.vue';
import * as moment from 'moment';

export default {
  filters: {
    time(value) {
      const [hour, min] = value.split(':');
      return `${hour}:${min}`;
    },
  },
  data: () => ({
    isLoading: false,
    days: {},
    weekdays: moment.weekdays(),
    standard_times: [20, 30, 45, 60],
  }),
  computed: {
    isEmpty() {
      return Object.keys(this.days).reduce((a, b) => a + b.length, 0) === 0;
    },
  },
  methods: {
    formatDate(date_start, date_end) {
      let date_result = '';
      date_result = date_start ? moment(date_start).format('DD/MM/YYYY') : '';
      date_result += date_start && date_end ? ' - ' : '';
      date_result += date_end ? moment(date_end).format('DD/MM/YYYY') : '';
      return date_result;
    },
    loadTimes() {
      this.isLoading = true;

      SchedulesService.getAvailabilities()
        .then((times) => (this.days = times))
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    newTime() {
      this.$buefy.modal.open({
        parent: this,
        component: TimeSchedule,
        hasModalCard: true,
        trapFocus: true,
        events: {
          close: (data) => data && this.loadTimes(),
        },
      });
    },
    deleteTime({ weekday, id }) {
      SchedulesService.deleteAvailability(id).then(() => {
        this.days[weekday] = this.days[weekday].filter(
          (time) => time.id !== id
        );
        this.$buefy.snackbar.open('Horário excluído com sucesso!');
      });
    },
    onClearClick(weekday) {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente excluir todos os horários de ${this.weekdays[weekday]}?`,
        onConfirm: () => this.clearWeekday(weekday),
      });
    },
    clearWeekday(weekday) {
      const promises = this.days[weekday].map((scd) =>
        SchedulesService.deleteAvailability(scd.id)
      );

      Promise.all(promises).then(() => {
        this.days[weekday] = [];
        this.$buefy.snackbar.open('Horários excluídos com sucesso!');
      });
    },
    getTimeClass({ appointment_types }) {
      return appointment_types.map(({ id }) => `apt-${id}`);
    },
  },
  mounted() {
    this.loadTimes();
  },
};
</script>

<style lang="scss" scoped>
.caption {
  margin-bottom: 8px !important;

  &__item {
    display: flex;
    align-items: center;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.apt-1 {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.apt-2 {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.apt-1.apt-2 {
  background: var(--color-dark);
  border-color: var(--color-dark);
}

.weekdays-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .weekday {
    min-width: 350px;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &__title {
      text-align: center;
      font-weight: bold;
      margin-bottom: 6px;
    }

    &__times {
      background: #f9f9f9;
      padding: 12px 16px;
      max-height: 200px;
      overflow-y: scroll;

      ol {
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          padding-left: 6px;
          margin-bottom: 6px;
          border: 1px solid;
          border-radius: 4px;

          button {
            background: transparent;
            color: #fff;

            &:hover {
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    &__clear {
      text-align: right;
    }
  }
}
</style>
