import Clear from '@/layouts/Clear.vue';

export default {
  path: '/appointments',
  alias: ['appointments'],
  component: Clear,
  children: [
    {
      path: '',
      name: 'procedures.create',
      component: () => import('@/views/Procedure/ProcedureForm.vue'),
    },
    {
      path: ':id',
      name: 'procedures.edit',
      component: () => import('@/views/Procedure/ProcedureForm.vue'),
    },
  ],
};
