<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Qual valor deseja transferir?</p>
      </header>
      <section class="modal-card-body">
        <div class="row mt-2">
          <div class="col-12">
            <div class="alert">
              A transferência será feita para a conta e CPF informados no
              cadastro.
            </div>
          </div>
          <div class="col-12">
            Você pode realizar uma transferência de até
            <b>R${{ transferableValue }}</b>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-field :message="hasError" :type="inputType">
              <b-input
                v-model="value"
                icon="dollar-sign"
                placeholder="Informe um valor"
                v-cleave="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  numeralDecimalMark: ',',
                  delimiter: '.',
                  numeralPositiveOnly: true,
                }"
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', false)">Cancelar</b-button>
        <b-button
          type="is-primary"
          @click="transferBalance"
          :disabled="hasError || !value"
          >Transferir</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transferableValue: Number,
  },
  computed: {
    hasError() {
      return this.value > this.transferableValue
        ? ['Saldo inválido para transferência']
        : null;
    },
    inputType() {
      return this.value > this.transferableValue ? 'is-danger' : '';
    },
  },
  data: () => ({
    value: null,
    errors: [],
  }),
  methods: {
    transferBalance() {
      this.$emit('close', { value: this.value });
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  margin-bottom: 10px;
  color: gray;
}
</style>
