<template>
  <div class="schedule-view">
    <app-header icon="calendar" title="Fale conosco"></app-header>

    <section>
      <div class="card">
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
          <div class="row">
            <div class="col-12">
              <app-input
                :placeholder="
                  'Olá, ' +
                  userName.split(' ').pop() +
                  ', como pudemos ajudá-lo?'
                "
                v-model="message"
                type="textarea"
              ></app-input>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <b-button
                @click="sendMessage"
                :loading="isLoading"
                :disabled="isLoading || !message"
                type="is-primary"
              >
                Enviar
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactUsService from '@/services/contact-us.service';

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['userName']),
  },
  data: () => ({
    isLoading: false,
    message: null,
  }),
  methods: {
    sendMessage() {
      if (this.message.length < 3) return;
      this.isLoading = true;

      ContactUsService.sendMessage({ message: this.message })
        .then(() => {
          this.$buefy.snackbar.open('Mensagem enviada com sucesso!');
        })
        .finally(() => {
          this.isLoading = false;
          this.message = '';
        });
    },
  },
};
</script>

<style lang="scss"></style>
